import {
  GET_HELP,
  GET_HELP_FAIL,
  GET_HELP_SUCCESS,
  SEND_HELP,
  SEND_HELP_FAIL,
  SEND_HELP_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

export const getHelp = (help, history) => {
  return {
    type: GET_HELP,
    payload: { help, history },
  };
};

export const getHelpSuccess = help => {
   toast.success(i18n.t(help.message));
  return {
    type: GET_HELP_SUCCESS,
    payload: help.details,
  };
};

export const getHelpFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_HELP_FAIL,
    payload: fail,
  };
};

export const sendHelp = (help, history) => {
  return {
    type: SEND_HELP,
    payload: { help, history },
  };
};

export const sendHelpSuccess = help => {
   toast.success(i18n.t(help.message));
  return {
    type: SEND_HELP_SUCCESS,
    payload: help.details,
  };
};

export const sendHelpFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: SEND_HELP_FAIL,
    payload: fail,
  };
};
