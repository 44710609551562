import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import PlaceDropdown from "../CommonForBoth/TopbarDropdown/PlaceDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

// import { ROOT_BUCKET } from "../../helpers/url";
// import logoDark from "../../assets/images/logo/logo-dark.png";

//i18n
import { withTranslation } from "react-i18next";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              {
                // <div className="navbar-brand-box">
                //   <Link to="/" className="logo logo-dark">
                //     <span className="logo-sm">
                //       <img
                //         src={
                //           this.props.match.params.idPlace
                //             ? ROOT_BUCKET +
                //               "/config/" +
                //               this.props.match.params.idPlace +
                //               "/logo-dark.png"
                //             : logoDark
                //         }
                //         alt=""
                //         height="22"
                //       />
                //     </span>
                //     <span className="logo-lg">
                //       <img
                //         src={
                //           this.props.match.params.idPlace
                //             ? ROOT_BUCKET +
                //               "/config/" +
                //               this.props.match.params.idPlace +
                //               "/logo-dark.png"
                //             : logoDark
                //         }
                //         alt=""
                //         height="42"
                //       />
                //     </span>
                //   </Link>
                // </div>
              }
              <PlaceDropdown />

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>

            <div className="d-flex">
              <ProfileMenu />
              <LanguageDropdown />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Header)));
