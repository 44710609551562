import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class Navbar extends Component {
  constructor(props) {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const selectedIdPlace = parseInt(props.match.params.idPlace);
    const selectedPlace = authUser.places.find(function (obj) {
      return selectedIdPlace === obj.id;
    });

    super(props);
    this.state = {
      selectedPlace,
      authUser,
    };
  }

  componentDidMount() {
    // this.setState({ authUser, selectedPlace });

    let matchingMenuItem = null;
    const ul = document.getElementById("navigation");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.idPlace !== prevProps.match.params.idPlace) {
      const newSelectedIdPlace = parseInt(this.props.match.params.idPlace);
      const selectedPlace = this.state.authUser.places.find(function (obj) {
        return newSelectedIdPlace === obj.id;
      });
      this.setState({ selectedPlace });
    }
  }

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="topnav"
          style={{
            backgroundColor: this.state.selectedPlace
              ? this.state.selectedPlace.dashboardColor
              : "#495057",
          }}
        >
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                {this.props.match.params.idPlace ? (
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={"/place/" + this.props.match.params.idPlace}
                      >
                        <i className="bx bx-home-circle me-2" />
                        {this.props.t("navbar.home")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={
                          "/place/" +
                          this.props.match.params.idPlace +
                          "/orders"
                        }
                      >
                        <i className="bx bx-copy-alt me-2" />
                        {this.props.t("navbar.orders")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={
                          "/place/" +
                          this.props.match.params.idPlace +
                          "/settings"
                        }
                      >
                        <i className="bx bx-cog me-2" />
                        {this.props.t("navbar.settings")}
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to={
                          "/place/" +
                          this.props.match.params.idPlace +
                          "/hardware"
                        }
                      >
                        <i className="bx bx-devices me-2" />
                        {this.props.t("navbar.hardware")}
                      </Link>
                    </li>

                    {/*<li className="nav-item">
                      <Link
                        className="nav-link"
                        to={
                          "/place/" +
                          this.props.match.params.idPlace +
                          "/invoicing"
                        }
                      >
                        <i className="bx bx-credit-card me-2" />
                        {this.props.t("navbar.invoicing")}
                      </Link>
                      </li>*/}
                  </ul>
                ) : (
                  <ul className="navbar-nav">
                    <li className="nav-item">
                      <Link className="nav-link" to={"/account"}>
                        <i className="bx bx-user me-2" />
                        {this.props.t("profile.title")}
                      </Link>
                    </li>
                  </ul>
                )}
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  match: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
