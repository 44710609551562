import {
  GET_HELP,
  GET_HELP_FAIL,
  GET_HELP_SUCCESS,
  SEND_HELP,
  SEND_HELP_FAIL,
  SEND_HELP_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: null,
  help: null,
  loading: false,
  helpSent: false
};

const Help = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELP:
      state = {
        ...state,
        help: null,
        loading: true,
        error: null,
      };
      break;
    case GET_HELP_SUCCESS:
      state = {
        ...state,
        loading: false,
        help: action.payload,
        error: null,
      };
      break;
    case GET_HELP_FAIL:
      state = {
        ...state,
        loading: false,
        help: null,
        error: action.payload,
      };
      break;
    case SEND_HELP:
      state = {
        ...state,
        helpSent: false,
        loading: true,
        error: null,
      };
      break;
    case SEND_HELP_SUCCESS:
      state = {
        ...state,
        helpSent : true,
        loading: false,
        error: null,
      };
      break;
    case SEND_HELP_FAIL:
      state = {
        ...state,
        helpSent : false,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Help;
