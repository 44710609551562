import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";

import Breadcrumbs from "../../components/Common/Breadcrumb";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../assets/scss/custom/plugins/_datatables.scss";

//store
import { listPlaces } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

import { ROOT_BUCKET } from "../../helpers/url";

class Places extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.listPlaces(this.props.history);
  }

  handleId(cell, row) {
    return (
      <div>
        <img
          src={ROOT_BUCKET + "/" + row.logo.replace("logo", "logo-dark")}
          style={{ height: "26px" }}
        ></img>
        {cell}
      </div>
    );
  }

  handleDetails(cell, row) {
    return (
      <span>
        <Trans i18nKey="places.hardwareCount"></Trans> : {row.hardwareCount}
        {row.sleeveOption ? (
          <span>
            {" "}
            <br />
            <Trans i18nKey="places.options"></Trans> :{" "}
            <Trans i18nKey="places.sleeveOption"></Trans>
          </span>
        ) : (
          ""
        )}
      </span>
    );
  }

  handleStock(cell, row) {
    return (
      <div>
        <span
          className={
            "text-" +
            (row.paperStockLeft <= 0
              ? "danger"
              : row.paperStockLeft <= 10
              ? "warning"
              : "success")
          }
        >
          <i className="far fa-copy"></i> <Trans i18nKey="place.paper" /> :{" "}
          {row.paperStockLeft}
        </span>
        {row.sleeveOption > 0 ? (
          <span>
            <br />
            <span
              className={
                "text-" +
                (row.sleeveStockLeft <= 0
                  ? "danger"
                  : row.sleeveStockLeft <= 10
                  ? "warning"
                  : "success")
              }
            >
              <i className="far fa-envelope"></i>{" "}
              <Trans i18nKey="place.sleeve" /> : {row.sleeveStockLeft}
            </span>
          </span>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const columns = [
      {
        dataField: "name",
        text: i18n.t("places.name"),
        formatter: this.handleId,
        sort: true,
        searchable: true,
      },
      {
        dataField: "sleeveOption",
        text: i18n.t("places.details"),
        formatter: this.handleDetails,
        sort: false,
        searchable: false,
      },
      {
        dataField: "paperStockLeft",
        text: i18n.t("places.stock"),
        formatter: this.handleStock,
        sort: false,
        searchable: false,
      },
      {
        dataField: "orderCount",
        text: i18n.t("places.orderCount"),
        sort: true,
        searchable: false,
      },
    ];

    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: (this.props.places && this.props.places.length) || 0, // replace later with size(customers),
      custom: true,
    };

    // Select All Button operation
    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      clickToSelect: true,
      classes: "bg-primary bg-soft",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>{i18n.t("sidebar.places") + " | Teampix"}</title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Teampix"}
              breadcrumbItem={this.props.t("sidebar.places")}
            />
            {this.props.places ? (
              <Row className="justify-content-center">
                <Col lg="12">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2 mb-5">
                        <h5 className="mt-3 mb-3">
                          <Trans i18nKey="sidebar.places" />
                        </h5>

                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="idPlace"
                          columns={columns}
                          data={this.props.places}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="idPlace"
                              columns={columns}
                              data={this.props.places}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            placeholder={i18n.t("place.search")}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"idPlace"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          hover={true}
                                          defaultSorted={defaultSorted}
                                          selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={
                                            <Trans i18nKey="places.emptyListPlaces"></Trans>
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      {
                                        // <div className="d-inline">
                                        //   <SizePerPageDropdownStandalone
                                        //     {...paginationProps}
                                        //   />
                                        // </div>
                                      }
                                      <div className="text-md-right ms-auto">
                                        <div className="pagination pagination-rounded justify-content-end">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                        <br />
                        <br />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Places.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  places: PropTypes.array,
  listPlaces: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, places } = state.Place;
  return { error, loading, places };
};

export default connect(mapStateToProps, {
  listPlaces,
})(withRouter(withTranslation()(Places)));
