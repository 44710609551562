export const PREFIX = "web.";
export const DOMAIN = "teampix.eu";

export const ROOT_CLIENT =
  window.location.host === "localhost:3000"
    ? "http://localhost:3000"
    : "https://" + PREFIX + DOMAIN;

export const ROOT_BUCKET =
  window.location.host === "localhost:3000"
    ? "http://localhost/sp/tpc/bucket"
    : "https://" + PREFIX + DOMAIN;

export const ROOT_SERVER =
  window.location.host.includes("localhost:3000")
    ? "http://localhost/sp/tpc/server/api"
    : "https://api." + DOMAIN;

//REGISTER
// export const POST_REGISTER = ROOT_SERVER + "/register";

//ACTIVATE
// export const POST_ACTIVATE = ROOT_SERVER + "/activate";

//LOGIN
export const POST_LOGIN = ROOT_SERVER + "/login";
export const POST_SEND_OTP = ROOT_SERVER + "/sendOTP";
export const POST_LOGOUT = ROOT_SERVER + "/logout";

//PROFILE
export const GET_PROFILE = ROOT_SERVER + "/profile/get";
export const EDIT_PROFILE = ROOT_SERVER + "/profile/edit";

export const EDIT_LANGUAGE = ROOT_SERVER + "/language/edit";

// UPLOAD
export const GET_UPLOAD = ROOT_SERVER + "/upload/get";
export const CONNECT_UPLOAD = ROOT_SERVER + "/upload/connect";
export const SUBMIT_UPLOAD = ROOT_SERVER + "/upload/submit";

// PLACE
export const GET_PLACE = ROOT_SERVER + "/place/get";
export const LIST_PLACES = ROOT_SERVER + "/place/list";
export const ADD_ORDER = ROOT_SERVER + "/place/addOrder";
export const CANCEL_ORDER = ROOT_SERVER + "/place/cancelOrder";
export const EDIT_PLACE = ROOT_SERVER + "/place/edit";

// HARDWARE
export const GET_HARDWARE = ROOT_SERVER + "/hardware/get";
export const LIST_HARDWARE = ROOT_SERVER + "/hardware/list";
export const EDIT_HARDWARE = ROOT_SERVER + "/hardware/edit";

// USER
export const GET_USER = ROOT_SERVER + "/user/get";
export const LIST_USERS = ROOT_SERVER + "/user/list";
export const LOGIN_AS = ROOT_SERVER + "/user/loginAs";

// ORDER
export const GET_ORDER = ROOT_SERVER + "/order/get";
export const LIST_ORDERS = ROOT_SERVER + "/order/list";
export const LAST_ORDERS = ROOT_SERVER + "/order/last";

// HELP
export const GET_HELP = ROOT_SERVER + "/help/get";
export const SEND_HELP = ROOT_SERVER + "/help/send";
