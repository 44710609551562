import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  subDomain,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (
        isAuthProtected > 0 &&
        subDomain == "web" &&
        (!localStorage.getItem("authUser") ||
          (isAuthProtected > 1 &&
            JSON.parse(localStorage.getItem("authUser")).user.type !== "staff"))
      ) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }
      return (
        <div>
          <Layout {...props}>
            <Component key={props.location.pathname} {...props} />
          </Layout>
        </div>
      );
    }}
  />
);

AppRoute.propTypes = {
  isAuthProtected: PropTypes.number,
  subDomain: PropTypes.string,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  user: PropTypes.object,
};

export default AppRoute;
