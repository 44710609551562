import axios from "axios";
// import { del, get, post, put } from "./api_helper";
import * as url from "./url";

// Register Method
// const registerCall = data => {
//   return axios
//     .post(url.POST_REGISTER, data, { withCredentials: true })
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299)
//         return response.data;
//       throw response.data;
//     })
//     .catch(err => {
//       let message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "backend.invalidCredentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// Activate Method
// const activateCall = data => {
//   return axios
//     .post(url.POST_ACTIVATE, data, { withCredentials: true })
//     .then(response => {
//       if (response.status >= 200 || response.status <= 299)
//         return response.data;
//       throw response.data;
//     })
//     .catch(err => {
//       let message;
//       if (err.response && err.response.status) {
//         switch (err.response.status) {
//           case 404:
//             message = "Sorry! the page you are looking for could not be found";
//             break;
//           case 500:
//             message =
//               "Sorry! something went wrong, please contact our support team";
//             break;
//           case 401:
//             message = "backend.invalidCredentials";
//             break;
//           default:
//             message = err[1];
//             break;
//         }
//       }
//       throw message;
//     });
// };

// Login Methods
const loginCall = data => {
  return axios
    .post(url.POST_LOGIN, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const sendOTPCall = data => {
  return axios
    .post(url.POST_SEND_OTP, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Profile Methods
const profileCall = () => {
  return axios
    .post(url.GET_PROFILE, {}, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const editProfileCall = data => {
  return axios
    .post(url.EDIT_PROFILE, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const editLanguageCall = data => {
  return axios
    .post(url.EDIT_LANGUAGE, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Logout Method
const logoutCall = () => {
  return axios
    .post(url.POST_LOGOUT, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Upload Methods
const connectUploadCall = data => {
  return axios
    .post(url.CONNECT_UPLOAD, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const getUploadCall = data => {
  return axios
    .post(url.GET_UPLOAD, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const submitUploadCall = (data, progressfunction) => {
  var formData = new FormData();
  formData.append("legend", data.legend);
  formData.append("idComputer", data.idComputer);
  formData.append("pin", data.pin);
  formData.append("quantity", data.quantity);
  formData.append("pack", data.pack);
  formData.append("photo", data.photo);
  formData.append("video", data.video);

  return axios
    .post(url.SUBMIT_UPLOAD, formData, {
      onUploadProgress: progressfunction,
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Place Methods
const getPlaceCall = data => {
  return axios
    .post(url.GET_PLACE, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const listPlacesCall = () => {
  return axios
    .post(url.LIST_PLACES, {}, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const addOrderCall = data => {
  return axios
    .post(url.ADD_ORDER, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const cancelOrderCall = data => {
  var formData = new FormData();
  formData.append("reason", data.reason);
  formData.append("idOrder", data.idOrder);
  formData.append("attachment1", data.attachment1);

  return axios
    .post(url.CANCEL_ORDER, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const editPlaceCall = data => {
  var formData = new FormData();
  formData.append("idPlace", data.idPlace);
  formData.append("name", data.name);
  formData.append("url", data.url);
  formData.append("logo", data.logo);
  formData.append("newLogo", data.newLogo);
  formData.append("color", data.color);
  formData.append("defaultLegend", data.defaultLegend);
  formData.append("defaultPack", data.defaultPack);
  formData.append("defaultQuantity", data.defaultQuantity);
  formData.append("defaultVideo", data.defaultVideo);
  formData.append("newDefaultVideo", data.newDefaultVideo);
  formData.append("firstname", data.firstname);
  formData.append("lastname", data.lastname);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("country", data.country);

  return axios
    .post(url.EDIT_PLACE, formData, {
      withCredentials: true,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const editHardwareCall = data => {
  return axios
    .post(url.EDIT_HARDWARE, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Hardware methods
const getHardwareCall = data => {
  return axios
    .post(url.GET_HARDWARE, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const listHardwareCall = () => {
  return axios
    .post(url.LIST_HARDWARE, {}, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Users methods
const getUserCall = data => {
  return axios
    .post(url.GET_USER, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const listUsersCall = () => {
  return axios
    .post(url.LIST_USERS, {}, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const loginAsCall = data => {
  return axios
    .post(url.LOGIN_AS, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Orders methods
const getOrderCall = data => {
  return axios
    .post(url.GET_ORDER, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const listOrdersCall = () => {
  return axios
    .post(url.LIST_ORDERS, {}, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const lastOrdersCall = () => {
  return axios
    .post(url.LAST_ORDERS, {}, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Help methods
const getHelpCall = data => {
  return axios
    .post(url.GET_HELP, data, { withCredentials: true })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

const sendHelpCall = data => {

  var formData = new FormData();
  formData.append("idPlace", data.idPlace);
  formData.append("idComputer", data.idComputer);
  formData.append("firstname", data.firstname);
  formData.append("lastname", data.lastname);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("message", data.message);
  formData.append("country", data.country);
  formData.append("i18n", data.i18n);
  formData.append("attachment1", data.attachment1);

  return axios
    .post(url.SEND_HELP, formData, { withCredentials: true, headers: {
      "Content-Type": "multipart/form-data",
    }, })
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "backend.invalidCredentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};
export {
  // registerCall,
  // activateCall,
  loginCall,
  sendOTPCall,
  profileCall,
  editProfileCall,
  editLanguageCall,
  logoutCall,
  getUploadCall,
  connectUploadCall,
  submitUploadCall,
  getPlaceCall,
  listPlacesCall,
  addOrderCall,
  cancelOrderCall,
  editPlaceCall,
  editHardwareCall,
  getHardwareCall,
  listHardwareCall,
  getUserCall,
  listUsersCall,
  getOrderCall,
  listOrdersCall,
  lastOrdersCall,
  loginAsCall,
  getHelpCall,
  sendHelpCall,
};
