import { takeEvery, put, call } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, SEND_OTP_USER, LOGOUT_USER } from "./actionTypes";
import {
  loginUserSuccess,
  loginUserFail,
  sendOTPSuccess,
  sendOTPFail,
  logoutUserSuccess,
  logoutUserFail,
} from "./actions";

//Include Both Helper File with needed methods
import { loginCall, sendOTPCall, logoutCall } from "../../../helpers/backend";

function* loginUser({ payload: { user, redirect, history } }) {
  try {
    const response = yield call(loginCall, user);
    if (response.status === "success") {
      localStorage.setItem("authUser", JSON.stringify(response.details));
      yield put(loginUserSuccess(response));
      if (redirect !== null) {
        history.push("/" + redirect);
      } else {
        if (response.details.user.type === "client") {
          if (response.details.places.length > 0)
            history.push("/place/" + response.details.places[0].id);
          else history.push("/profile");
        } else {
          history.push("/dashboard");
        }
      }
    } else yield put(loginUserFail(response));
  } catch (error) {
    yield put(loginUserFail(error));
  }
}

function* sendOTP({ payload: otp }) {
  try {
    const response = yield call(sendOTPCall, otp);
    if (response.status === "success") {
      yield put(sendOTPSuccess(response));
    } else yield put(sendOTPFail(response));
  } catch (error) {
    yield put(sendOTPFail(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const response = yield call(logoutCall);
    if (response.status === "success") {
      yield put(logoutUserSuccess(response));
      localStorage.removeItem("authUser");
      history.push("/login");
    } else yield put(logoutUserFail(response));
  } catch (error) {
    yield put(logoutUserFail(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(SEND_OTP_USER, sendOTP);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
