export const GET_PLACE = "GET_PLACE";
export const GET_PLACE_SUCCESS = "GET_PLACE_SUCCESS";
export const GET_PLACE_FAIL = "GET_PLACE_FAIL";

export const LIST_PLACES = "LIST_PLACES";
export const LIST_PLACES_SUCCESS = "LIST_PLACES_SUCCESS";
export const LIST_PLACES_FAIL = "LIST_PLACES_FAIL";

export const ADD_ORDER = "ADD_ORDER";
export const ADD_ORDER_SUCCESS = "ADD_ORDER_SUCCESS";
export const ADD_ORDER_FAIL = "ADD_ORDER_FAIL";

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";

export const EDIT_PLACE = "EDIT_PLACE";
export const EDIT_PLACE_SUCCESS = "EDIT_PLACE_SUCCESS";
export const EDIT_PLACE_FAIL = "EDIT_PLACE_FAIL";


export const EDIT_HARDWARE = "EDIT_HARDWARE";
export const EDIT_HARDWARE_SUCCESS = "EDIT_HARDWARE_SUCCESS";
export const EDIT_HARDWARE_FAIL = "EDIT_HARDWARE_FAIL";