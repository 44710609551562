import PropTypes from "prop-types";
import React, { Component } from "react";

//Simple bar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    this.state = { authUser };
    this.refDiv = React.createRef();
    this.togglemodal.bind(this);
  }

  togglemodal = (id, messageType) => {
    this.setState(prevState => ({
      modal: !prevState.modal,
      selectedPlace: id !== undefined ? id.toString() : "0",
      messageType,
    }));
  };

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.selectedMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    this.selectedMenu();
  }

  selectedMenu() {
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      items[i].classList.remove("active");
      items[i].classList.remove("mm-active");
      items[i].parentElement.parentElement.classList.remove("mm-show");
      items[i].parentElement.classList.remove("mm-active");
      const currentLocation = this.props.location.pathname;
      if (currentLocation.startsWith(items[i].getAttribute("alt"))) {
        matchingMenuItem = items[i];
        // break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  scrollElement = item => {
    setTimeout(() => {
      if (this.refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (this.refDiv.current)
              this.refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      this.scrollElement(item);
      return false;
    }
    this.scrollElement(item);
    return false;
  };

  render() {
    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={this.refDiv}>
          {this.state.authUser ? (
            <div id="sidebar-menu"> 
              {this.state.authUser.user.type === "client" ? (
                <ul className="metismenu list-unstyled" id="side-menu">
                 {/*<li className="menu-title">{this.props.t("sidebar.menu")}</li>

                  <li>
                    <Link to="/home" className="" alt="/home">
                      <i className="bx bxs-dashboard" />
                      <span>{this.props.t("sidebar.dashboard")}</span>
                    </Link>
              </li>*/}

                  {this.state.authUser.places &&
                  this.state.authUser.places.length > 0 ? (
                    <li className="menu-title">
                      {this.props.t("sidebar.places")}
                    </li>
                  ) : (
                    ""
                  )}
                  {this.state.authUser.places &&
                  this.state.authUser.places.length > 0 ? (
                    this.state.authUser.places.length > 1 ? (
                      this.state.authUser.places.map((place, i) => {
                        return (
                          <li key={"place" + i}>
                            <Link
                              to={"/place/" + place.id}
                              alt={"/place/" + place.id}
                            >
                              <i className="bx bx bxs-store"></i>
                              <span>{place.name}</span>
                            </Link>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <Link
                          to={"/place/" + this.state.authUser.places[0].id}
                          alt={"/place/" + this.state.authUser.places[0].id}
                        >
                          <i className="bx bx bxs-store"></i>
                          <span>{this.state.authUser.places[0].name}</span>
                        </Link>
                      </li>
                    )
                  ) : (
                    ""
                  )}
                </ul>
              ) : (
                <ul className="metismenu list-unstyled" id="side-menu">
                  <li className="menu-title">{this.props.t("sidebar.menu")}</li>

                  <li>
                    <Link to="/dashboard" className="" alt="/dashboard">
                      <i className="bx bxs-dashboard" />
                      <span>{this.props.t("sidebar.dashboard")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/places" className="" alt="/places">
                      <i className="bx bxs-store" />
                      <span>{this.props.t("sidebar.places")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/hardware" className="" alt="/hardware">
                      <i className="bx bx-printer" />
                      <span>{this.props.t("sidebar.hardware")}</span>
                    </Link>
                  </li>
                  <li>
                  <Link to="/users" className="" alt="/users">
                    <i className="bx bx-user" />
                    <span>{this.props.t("sidebar.users")}</span>
                  </Link>
                </li>
                  <li>
                    <Link to="/orders" className="" alt="/orders">
                      <i className="bx bx-receipt" />
                      <span>{this.props.t("sidebar.orders")}</span>
                    </Link>
                  </li>

                </ul>
              )}
            </div>
          ) : (
            ""
          )}
        </SimpleBar>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  place: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  router: PropTypes.object,
};

// const mapStateToProps = state => {
//   const { error, loading, user, places } = state.Profile;
//   return { error, loading, user, places };
// };

export default withRouter(withTranslation()(SidebarContent));
