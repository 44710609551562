import { takeEvery, fork, put, call, all, take } from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";

// Login Redux States
import { GET_UPLOAD, CONNECT_UPLOAD, SUBMIT_UPLOAD, SUBMIT_UPLOAD_PROGRESS } from "./actionTypes";
import {
  getUploadSuccess,
  getUploadFail,
  connectUploadSuccess,
  connectUploadFail,
  submitUploadSuccess,
  submitUploadFail,
  submitUploadProgress,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getUploadCall,
  connectUploadCall,
  submitUploadCall,
} from "../../helpers/backend";

function* getUpload({ payload: { upload, history } }) {
  try {
    const response = yield call(getUploadCall, upload);
    if (response.status === "success") {
      yield put(getUploadSuccess(response));
    } else {
      yield put(getUploadFail(response));
      if (response.message === "upload.backend.notFound")
        history.push("/404");
    }
  } catch (error) {
    yield put(getUploadFail(error));
    if (error === "backend.invalidCredentials") history.push("/logout");
  }
}

function* connectUpload({ payload: { upload } }) {
  try {
    const response = yield call(connectUploadCall, upload);
    if (response.status === "success") {
      yield put(connectUploadSuccess(response));
    } else yield put(connectUploadFail(response));
  } catch (error) {
    yield put(connectUploadFail(error));
    // if (error === "backend.invalidCredentials") history.push("/logout");
  }
}

function createUploader(upload) {
  let emit;
  const chan = eventChannel(emitter => {
    emit = emitter;
    return () => { }; // it's necessarily. event channel should
    // return unsubscribe function. In our case
    // it's empty function
  });

  const uploadPromise = submitUploadCall(upload, event => {
    emit(event.progress);
    if (event.loaded >= event.total) {
      emit(END);
    } 
  });

  return [uploadPromise, chan];
}

function* watchOnProgress(chan) {
  while (true) {
    const response = yield take(chan);
    // if (response.progress !== null)
      yield put(submitUploadProgress(response * 100));
    // yield put({ type: "PROGRESS", payload: data });
  }
}

function* submitUpload({ payload: { upload, history } }) {
  const [uploadPromise, chan] = createUploader(upload);
  yield fork(watchOnProgress, chan);

  try {
    const response = yield call(() => uploadPromise);
    if (response.status === "success") {
      yield put(submitUploadSuccess(response));
      history.push("/upload/" + upload.idComputer);
    } else yield put(submitUploadFail(response));
  } catch (error) {
    yield put(submitUploadFail(error));
  }
}

// function* submitUpload({ payload: { upload, history } }) {
//   try {
//     const response = yield call(submitUploadCall, upload);
//     if (response.status === "success") {
//       yield put(submitUploadSuccess(response));
//       history.push("/upload/" + upload.idComputer);
//     } else yield put(submitUploadFail(response));
//   } catch (error) {
//     yield put(submitUploadFail(error));
//   }
// }

export function* watchUpload() {
  yield takeEvery(GET_UPLOAD, getUpload);
  yield takeEvery(CONNECT_UPLOAD, connectUpload);
  yield takeEvery(SUBMIT_UPLOAD, submitUpload);
  yield takeEvery(SUBMIT_UPLOAD_PROGRESS, submitUploadProgress);
}

function* UploadSaga() {
  yield all([fork(watchUpload)]);
}

export default UploadSaga;
