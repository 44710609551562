export const GET_UPLOAD = "GET_UPLOAD";
export const GET_UPLOAD_SUCCESS = "GET_UPLOAD_SUCCESS";
export const GET_UPLOAD_FAIL = "GET_UPLOAD_FAIL";

export const CONNECT_UPLOAD = "CONNECT_UPLOAD";
export const CONNECT_UPLOAD_SUCCESS = "CONNECT_UPLOAD_SUCCESS";
export const CONNECT_UPLOAD_FAIL = "CONNECT_UPLOAD_FAIL";

export const SUBMIT_UPLOAD = "SUBMIT_UPLOAD";
export const SUBMIT_UPLOAD_PROGRESS = "SUBMIT_UPLOAD_PROGRESS";
export const SUBMIT_UPLOAD_SUCCESS = "SUBMIT_UPLOAD_SUCCESS";
export const SUBMIT_UPLOAD_FAIL = "SUBMIT_UPLOAD_FAIL";
