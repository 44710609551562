// URL local : http://help.localhost:3000/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, Container } from "reactstrap";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

//store
import { getHelp, sendHelp } from "../../../store/actions";

//i18n
import i18n from "../../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

import { Helmet, HelmetProvider } from "react-helmet-async";

import LanguageDropdown from "../../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

import logo from "../../../assets/images/logo/logo-light-hd.png";

import FAQ from "./FAQ";
// import RightBar from "./RightBar";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: "grid",
    };
  }

  componentDidMount() {
    if (this.props.match.params.idComputer) {
      const postData = {
        idComputer: this.props.match.params.idComputer,
      };
      this.props.getHelp(postData, this.props.history);
    }
  }

  render() {
    // if (!this.props.help) return "";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <HelmetProvider>
            <Helmet>
              <title>{i18n.t("help.title") + " | Teampix"}</title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            <Row className="justify-content-center">
              <Col md={12} lg={10} xl={8}>
                <Card>
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-3">
                        <img src={logo} alt="" className="img-fluid" />
                      </Col>
                      <Col className="col-9" style={{ textAlign: "right" }}>
                        <LanguageDropdown />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-12 text-center">
                        <div className="text-primary">
                          <h5 className="text-primary">
                            <Trans i18nKey="help.title"></Trans>
                          </h5>
                          <p>
                            {" "}
                            <Trans i18nKey="help.description"></Trans>
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {
                    // <CardImg
                    //   src={
                    //     ROOT_BUCKET +
                    //     "/config/" +
                    //     this.props.help.idPlace +
                    //     "/logo.png"
                    //   }
                    //   alt=""
                    //   style={{
                    //     backgroundColor: this.props.help.color,
                    //     maxWidth: "100px",
                    //   }}
                    // />
                  }
                  <CardBody>
                    {this.props.helpSent ? (
                      <Row>
                        <Col className="text-center">
                          <div className="p-2 mt-4">
                            <h4>
                              <Trans i18nKey="help.success"></Trans>
                            </h4>
                            <p className="text-muted">
                              <Trans i18nKey="help.resolvingInProgress"></Trans>
                            </p>
                            <div className="mt-4">
                              <Link
                                to=""
                                onClick={() => window.location.reload()}
                                className="btn btn-primary"
                              >
                                <Trans i18nKey="help.returnToFAQ"></Trans>
                              </Link>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <FAQ
                          help={this.props.help}
                          sendHelp={this.props.sendHelp}
                          loading={this.props.loading}
                          helpSent={this.props.helpSent}
                        />
                        {
                          //  <RightBar />
                        }
                      </Row>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="text-center">
              <p className="text-dark text-opacity-50">
                © {new Date().getFullYear()} Teampix.{" "}
                <Trans i18nKey="login.craftedWith" />{" "}
                <i className="mdi mdi-heart text-danger" />{" "}
                <Trans i18nKey="login.by" /> Teampix
              </p>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Help.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  help: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  getHelp: PropTypes.func,
  sendHelp: PropTypes.func,
  helpSent: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, loading, help, helpSent } = state.Help;
  return { error, loading, help, helpSent };
};

export default connect(mapStateToProps, {
  getHelp,
  sendHelp,
})(withRouter(withTranslation()(Help)));
