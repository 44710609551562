import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  SEND_OTP_USER,
  SEND_OTP_USER_SUCCESS,
  SEND_OTP_USER_FAIL,
  GET_LOCALITIES,
  GET_LOCALITIES_SUCCESS,
  GET_LOCALITIES_FAIL,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  OTPsent: false,
  localities: [],
  user: {},
  places: []
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
        user: {},
        places: []
      };
      break;
    case LOGIN_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.details.user,
        places: action.payload.details.places
      };
      break;
    case LOGIN_USER_FAIL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case SEND_OTP_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SEND_OTP_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        OTPsent: true,
      };
      break;
    case SEND_OTP_USER_FAIL:
      state = {
        ...state,
        loading: false,
        OTPsent: false,
      };
      break;
    case GET_LOCALITIES:
      state = {
        ...state,
        loading: false
      };
      break;
    case GET_LOCALITIES_SUCCESS:
      state = {
        ...state,
        loading: false,
        localities : action.payload.details
      };
      break;
    case GET_LOCALITIES_FAIL:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
