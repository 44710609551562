import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";

// form
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

// notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//store
import { getPlace, editPlace } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

import ReactPlayer from "react-player";

import { ROOT_BUCKET } from "../../helpers/url";

// file type
import {
  LOGO_FILE_TYPE,
  LOGO_FILE_SIZE,
  DEFAULT_VIDEO_FILE_TYPE,
  DEFAULT_VIDEO_FILE_SIZE,
} from "../../helpers/files";

class Place extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.idPlace > 0) {
      const postData = {
        idPlace: this.props.match.params.idPlace,
        type: "settings",
      };
      this.props.getPlace(postData, this.props.history);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (this.props.place && this.props.place.settings
                    ? " : " + this.props.place.settings.name
                    : "") +
                  " | Teampix"}
              </title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {this.props.place && this.props.place.settings ? (
              <div>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              idPlace: this.props.match.params.idPlace || 0,
                              name: this.props.place.settings.name || "",
                              url: this.props.place.settings.url || "",
                              logo:
                                (this.props.place.settings.logo &&
                                  ROOT_BUCKET +
                                    "/" +
                                    this.props.place.settings.logo) ||
                                "",
                              newLogo: null,
                              color:
                                this.props.place.settings.color || "#C387EB",
                              defaultPack:
                                this.props.place.settings.defaultPack || "",
                              defaultQuantity:
                                this.props.place.settings.defaultQuantity || "",
                              defaultLegend:
                                this.props.place.settings.defaultLegend ||
                                "{date} - {place}",
                              defaultVideo:
                                (this.props.place.settings.defaultVideo &&
                                  ROOT_BUCKET +
                                    "/" +
                                    this.props.place.settings.defaultVideo) ||
                                "",
                              newDefaultVideo: null,
                              firstname:
                                this.props.place.settings.firstname || "",
                              lastname:
                                this.props.place.settings.lastname || "",
                              email: this.props.place.settings.email || "",
                              phone: this.props.place.settings.phone || "",
                              country:
                                this.props.place.settings.country ||
                                i18n.t("profile.defaultCountry") ||
                                "FR",
                            }}
                            validationSchema={Yup.object().shape({
                              url: Yup.string()
                                .required(i18n.t("place.enterUrl"))
                                .matches(
                                  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                  i18n.t("place.enterValidUrl")
                                ),
                              name: Yup.string().required(
                                i18n.t("place.enterName")
                              ),
                              logo: Yup.string(),
                              newLogo: Yup.mixed().when("logo", {
                                is: logo =>
                                  logo == null ||
                                  logo == undefined ||
                                  logo === "",
                                then: Yup.mixed()
                                  .required(i18n.t("place.enterLogo"))
                                  .test(
                                    "fileFormat",
                                    i18n.t("place.PNGOnly"),
                                    value => {
                                      return value
                                        ? LOGO_FILE_TYPE.includes(value.type)
                                        : true;
                                    }
                                  )
                                  .test(
                                    "fileSize",
                                    i18n.t("place.logoTooLarge"),
                                    value => {
                                      return value
                                        ? value.size <= LOGO_FILE_SIZE
                                        : true;
                                    }
                                  ),
                                otherwise: Yup.mixed()
                                  .test(
                                    "fileFormat",
                                    i18n.t("place.PNGOnly"),
                                    value => {
                                      return value
                                        ? LOGO_FILE_TYPE.includes(value.type)
                                        : true;
                                    }
                                  )
                                  .test(
                                    "fileSize",
                                    i18n.t("place.logoTooLarge"),
                                    value => {
                                      return value
                                        ? value.size <= LOGO_FILE_SIZE
                                        : true;
                                    }
                                  ),
                              }),
                              newDefaultVideo: Yup.mixed().when(
                                "defaultVideo",
                                {
                                  is: defaultVideo =>
                                    defaultVideo == null ||
                                    defaultVideo == undefined ||
                                    defaultVideo === "",
                                  then: Yup.mixed()
                                    //.required(i18n.t("place.enterDefaultVideo"))
                                    .test(
                                      "fileFormat",
                                      i18n.t("place.MP4Only"),
                                      value => {
                                        return value
                                          ? DEFAULT_VIDEO_FILE_TYPE.includes(
                                              value.type
                                            )
                                          : true;
                                      }
                                    )
                                    .test(
                                      "fileSize",
                                      i18n.t("place.defaultVideoTooLarge"),
                                      value => {
                                        return value
                                          ? value.size <=
                                              DEFAULT_VIDEO_FILE_SIZE
                                          : true;
                                      }
                                    ),
                                  otherwise: Yup.mixed()
                                    .test(
                                      "fileFormat",
                                      i18n.t("place.MP4Only"),
                                      value => {
                                        return value
                                          ? DEFAULT_VIDEO_FILE_TYPE.includes(
                                              value.type
                                            )
                                          : true;
                                      }
                                    )
                                    .test(
                                      "fileSize",
                                      i18n.t("place.defaultVideoTooLarge"),
                                      value => {
                                        return value
                                          ? value.size <=
                                              DEFAULT_VIDEO_FILE_SIZE
                                          : true;
                                      }
                                    ),
                                }
                              ),
                              email: Yup.string().email(
                                i18n.t("profile.enterValidEmail")
                              ),
                              phone: Yup.string().test({
                                name: "phone",
                                message: i18n.t("profile.enterValidPhone"),
                                test: function (value) {
                                  if (value === undefined) return true;
                                  return isValidPhoneNumber(value);
                                },
                              }),
                            })}
                            onSubmit={values => {
                              this.props.editPlace(values, this.props.history);
                            }}
                          >
                            {({
                              handleBlur,
                              setFieldValue,
                              values,
                              errors,
                              touched,
                            }) => (
                              <Form className="form-horizontal">
                                <Row>
                                  <Col lg={4}>
                                    <h5 className="mt-3 mb-3">
                                      <Trans i18nKey="place.general" />
                                    </h5>
                                    <div className="mb-3">
                                      <Label for="name" className="form-label">
                                        <Trans i18nKey="place.name" /> *
                                      </Label>
                                      <Field
                                        name="name"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.name && touched.name
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue("name", value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label for="url" className="form-label">
                                        <Trans i18nKey="place.url" /> *
                                      </Label>
                                      <Field
                                        name="url"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.url && touched.url
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue("url", value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="url"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <h5 className="mt-3 mb-3">
                                      <Trans i18nKey="place.design" />
                                    </h5>
                                    <div className="mb-3">
                                      <Label for="color" className="form-label">
                                        <Trans i18nKey="place.color" /> *
                                      </Label>

                                      <Field
                                        name="color"
                                        type="color"
                                        className={
                                          "form-control form-control-color mw-100 " +
                                          (errors.color && touched.color
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue("color", value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="color"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label for="logo" className="form-label">
                                        <Trans i18nKey="place.logo" />
                                      </Label>
                                      <input
                                        name="newLogo"
                                        type="file"
                                        accept={LOGO_FILE_TYPE.join(",")}
                                        className={
                                          "form-control" +
                                          (errors.newLogo && touched.newLogo
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value =
                                            e.target.files[0] || null;
                                          setFieldValue("newLogo", value);
                                          let reader = new FileReader();
                                          reader.onloadend = () => {
                                            setFieldValue(
                                              "logo",
                                              reader.result
                                            );
                                          };
                                          reader.readAsDataURL(value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="newLogo"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>

                                    {values.logo &&
                                    values.logo !== null &&
                                    values.logo !== undefined &&
                                    values.logo !== "" ? (
                                      <Col className="text-center">
                                        <img
                                          src={values.logo}
                                          alt={i18n.t("place.logo")}
                                          name={i18n.t("place.logo")}
                                          className="img-thumbnail"
                                          style={{
                                            backgroundColor: values.color,
                                          }}
                                        />
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col lg={4}>
                                    <h5 className="mt-3 mb-3">
                                      <Trans i18nKey="place.defaultParameters" />
                                    </h5>
                                    <div className="mb-3">
                                      <Label
                                        for="defaultPack"
                                        className="form-label"
                                      >
                                        <Trans i18nKey="place.defaultPack" /> *
                                      </Label>
                                      <Field
                                        name="defaultPack"
                                        as="select"
                                        className={
                                          "form-control" +
                                          (errors.defaultPack &&
                                          touched.defaultPack
                                            ? " is-invalid"
                                            : "")
                                        }
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      >
                                        <option
                                          value=""
                                          disabled={true}
                                          className={"text-disabled"}
                                        >
                                          {i18n.t("place.chooseDefaultPack")}
                                        </option>
                                        {this.props.place.settings.packList.indexOf(
                                          "standard"
                                        ) === -1 ? (
                                          ""
                                        ) : (
                                          <option
                                            value="standard"
                                            key={"standard"}
                                          >
                                            {i18n.t("upload.standardPack")}
                                          </option>
                                        )}
                                        {this.props.place.settings.packList.indexOf(
                                          "birthday"
                                        ) === -1 ? (
                                          ""
                                        ) : (
                                          <option
                                            value="birthday"
                                            key={"birthday"}
                                          >
                                            {i18n.t("upload.birthdayPack")}
                                          </option>
                                        )}
                                        {this.props.place.settings.packList.indexOf(
                                          "premium"
                                        ) === -1 ? (
                                          ""
                                        ) : (
                                          <option
                                            value="premium"
                                            key={"premium"}
                                          >
                                            {i18n.t("upload.premiumPack")}
                                          </option>
                                        )}
                                        {this.props.place.settings.packList.indexOf(
                                          "solo"
                                        ) === -1 ? (
                                          ""
                                        ) : (
                                          <option
                                            value="solo"
                                            key={"solo"}
                                          >
                                            {i18n.t("upload.soloPack")}
                                          </option>
                                        )}
                                        {this.props.place.settings.packList.indexOf(
                                          "family"
                                        ) === -1 ? (
                                          ""
                                        ) : (
                                          <option
                                            value="family"
                                            key={"family"}
                                          >
                                            {i18n.t("upload.familyPack")}
                                          </option>
                                        )}
                                        {this.props.place.settings.packList.indexOf(
                                          "group"
                                        ) === -1 ? (
                                          ""
                                        ) : (
                                          <option
                                            value="group"
                                            key={"group"}
                                          >
                                            {i18n.t("upload.groupPack")}
                                          </option>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name="defaultPack"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        for="defaultQuantity"
                                        className="form-label"
                                      >
                                        <Trans i18nKey="place.defaultQuantity" />{" "}
                                        *
                                      </Label>
                                      <Field
                                        name="defaultQuantity"
                                        label={i18n.t(
                                          "upload.yourDefaultQuantity"
                                        )}
                                        as="select"
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                        className={
                                          "form-control" +
                                          (errors.defaultQuantity &&
                                          touched.defaultQuantity
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue(
                                            "defaultQuantity",
                                            value
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      >
                                        {[...Array(50)].map((x, i) => (
                                          <option key={i + 1} value={i + 1}>
                                            {i + 1}
                                          </option>
                                        ))}
                                      </Field>
                                      <ErrorMessage
                                        name="defaultQuantity"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        for="defaultLegend"
                                        className="form-label"
                                      >
                                        <Trans i18nKey="place.defaultLegend" />
                                      </Label>
                                      <Field
                                        name="defaultLegend"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.defaultLegend &&
                                          touched.defaultLegend
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue("defaultLegend", value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="defaultLegend"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        for="newDefaultVideo"
                                        className="form-label"
                                      >
                                        <Trans i18nKey="place.newDefaultVideo" />{" "}
                                      </Label>

                                      <input
                                        name="newDefaultVideo"
                                        type="file"
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                        accept="video/mp4,video/quicktime"
                                        className={
                                          "form-control" +
                                          (errors.newDefaultVideo &&
                                          touched.newDefaultVideo
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value =
                                            e.target.files[0] || null;
                                          setFieldValue(
                                            "newDefaultVideo",
                                            value
                                          );
                                          let reader = new FileReader();
                                          reader.onloadend = () => {
                                            setFieldValue(
                                              "defaultVideo",
                                              reader.result
                                            );
                                          };
                                          reader.readAsDataURL(value);
                                        }}
                                        onBlur={handleBlur}
                                      />

                                      <ErrorMessage
                                        name="newDefaultVideo"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    {values.defaultVideo !== "" ? (
                                      <div className="ratio ratio-16x9">
                                        <ReactPlayer
                                          className="react-player"
                                          url={values.defaultVideo}
                                          width="100%"
                                          height="100%"
                                          controls={true}
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                  <Col lg={4}>
                                    <h5 className="mt-3 mb-3">
                                      <Trans i18nKey="place.defaultContact" />
                                    </h5>
                                    <div className="mb-3">
                                      <Label
                                        for="firstname"
                                        className="form-label"
                                      >
                                        <Trans i18nKey="profile.firstname" />
                                      </Label>
                                      <Field
                                        name="firstname"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.firstname && touched.firstname
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue("firstname", value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="firstname"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label
                                        for="lastname"
                                        className="form-label"
                                      >
                                        <Trans i18nKey="profile.lastname" />
                                      </Label>
                                      <Field
                                        name="lastname"
                                        type="text"
                                        className={
                                          "form-control" +
                                          (errors.lastname && touched.lastname
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue("lastname", value);
                                        }}
                                        onBlur={handleBlur}
                                        disabled={
                                          this.props.loading ||
                                          this.state.processing
                                        }
                                      />
                                      <ErrorMessage
                                        name="lastname"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <Label for="email" className="form-label">
                                        <Trans i18nKey="profile.email" />
                                      </Label>
                                      <Field
                                        name="email"
                                        type="email"
                                        className={
                                          "form-control" +
                                          (errors.email && touched.email
                                            ? " is-invalid"
                                            : "")
                                        }
                                        onChange={e => {
                                          const value = e.target.value || "";
                                          setFieldValue(
                                            "email",
                                            value.toLowerCase()
                                          );
                                        }}
                                        onBlur={handleBlur}
                                      />
                                      <ErrorMessage
                                        name="email"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>

                                    <div className="mb-3">
                                      <Label for="phone" className="form-label">
                                        <Trans i18nKey="profile.phone" />
                                      </Label>

                                      <PhoneInput
                                        name="phone"
                                        type="tel"
                                        className={
                                          "form-control" +
                                          (errors.phone && touched.phone
                                            ? " is-invalid"
                                            : "")
                                        }
                                        defaultCountry={
                                          this.props.place.settings.country
                                        }
                                        value={this.props.place.settings.phone}
                                        onChange={phone => {
                                          setFieldValue("phone", phone);
                                        }}
                                        onCountryChange={countryCode => {
                                          setFieldValue("country", countryCode);
                                        }}
                                        countries={["FR", "BE"]}
                                      />

                                      <ErrorMessage
                                        name="phone"
                                        component="div"
                                        className="invalid-feedback"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <div className="mt-3 d-grid">
                                  <button
                                    className="btn btn-primary btn-block"
                                    style={{
                                      backgroundColor:
                                        this.props.place.settings
                                          .dashboardColor,
                                      borderColor:
                                        this.props.place.settings
                                          .dashboardColor,
                                    }}
                                    type="submit"
                                    disabled={
                                      this.props.loading ||
                                      this.state.processing
                                    }
                                  >
                                    {this.props.loading ? (
                                      <i className="fas fa-circle-notch fa-spin"></i>
                                    ) : (
                                      <Trans i18nKey="place.save" />
                                    )}
                                  </button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Place.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  getPlace: PropTypes.func,
  editPlace: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, place } = state.Place;
  return { error, loading, place };
};

export default connect(mapStateToProps, {
  getPlace,
  editPlace,
})(withRouter(withTranslation()(Place)));
