import {
  GET_UPLOAD,
  GET_UPLOAD_FAIL,
  GET_UPLOAD_SUCCESS,
  CONNECT_UPLOAD,
  CONNECT_UPLOAD_FAIL,
  CONNECT_UPLOAD_SUCCESS,
  SUBMIT_UPLOAD,
  SUBMIT_UPLOAD_PROGRESS,
  SUBMIT_UPLOAD_FAIL,
  SUBMIT_UPLOAD_SUCCESS,
} from "./actionTypes";

const initialState = {
  error: null,
  upload: null,
  done: false,
  loading: false,
  progress: 0
};

const Upload = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPLOAD:
      state = {
        ...state,
        upload: null,
        done: false,
        loading: true,
        error: null,
      };
      break;
    case GET_UPLOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        upload: action.payload,
        error: null,
      };
      break;
    case GET_UPLOAD_FAIL:
      state = {
        ...state,
        loading: false,
        upload: null,
        error: action.payload,
      };
      break;
    case CONNECT_UPLOAD:
      state = {
        ...state,
        done: false,
        loading: true,
        error: null,
      };
      break;
    case CONNECT_UPLOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        upload: action.payload,
        error: null,
      };
      break;
    case CONNECT_UPLOAD_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    case SUBMIT_UPLOAD:
      state = {
        ...state,
        loading: true,
        error: null,
      };
      break;
    case SUBMIT_UPLOAD_PROGRESS:
      state = {
        ...state,
        loading: true,
        progress: action.payload,
      };
      break;
    case SUBMIT_UPLOAD_SUCCESS:
      state = {
        ...state,
        done: true,
        loading: false,
        error: null,
      };
      break;
    case SUBMIT_UPLOAD_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Upload;
