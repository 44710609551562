import { all, fork } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"

//public
// import AccountSaga from "./authentication/register/saga"
import AuthSaga from "./authentication/login/saga"
// import ActivateSaga from "./authentication/activate/saga"
import ProfileSaga from "./authentication/profile/saga"
import UploadSaga from "./upload/saga"
import PlaceSaga from "./place/saga"
import HardwareSaga from "./hardware/saga"
import UserSaga from "./user/saga"
import OrderSaga from "./order/saga"
import HelpSaga from "./help/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    // fork(AccountSaga),
    fork(AuthSaga),
    // fork(ActivateSaga),
    fork(ProfileSaga),
    fork(UploadSaga),
    fork(PlaceSaga),
    fork(HardwareSaga),
    fork(UserSaga),
    fork(OrderSaga),
    fork(HelpSaga),
  ])
}
