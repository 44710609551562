import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardImg,
  CardBody,
  Col,
  Row,
  Container,
  CardHeader,
} from "reactstrap";
import { ROOT_BUCKET, PREFIX, DOMAIN } from "../../../helpers/url";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//store
import { getOrder } from "../../../store/actions";

import { Helmet, HelmetProvider } from "react-helmet-async";

//i18n
import { Trans, withTranslation } from "react-i18next";

import ReactPlayer from "react-player";
import LanguageDropdown from "../../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

import { DateTime } from "luxon";

class LandingPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.match.params.idOrder > 0 &&
      this.props.match.params.idComputer > 0 &&
      this.props.match.params.idPlace > 0 &&
      this.props.match.params.token.length > 0
    ) {
      const postData = {
        idOrder: this.props.match.params.idOrder,
        idPlace: this.props.match.params.idPlace,
        idComputer: this.props.match.params.idComputer,
        token: this.props.match.params.token,
        public: true,
      };
      this.props.getOrder(postData, this.props.history);
    }
  }

  render() {
    if (!this.props.order) return "";
    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <HelmetProvider>
            <Helmet>
              <title>{this.props.order.name + " | Teampix"}</title>
              <link
                rel="icon"
                type="image/png"
                href={
                  ROOT_BUCKET +
                  "/config/" +
                  this.props.order.idPlace +
                  "/logo.png"
                }
                sizes="16x16"
              />
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: this.props.order.color,
                      textAlign: "center",
                    }}
                  >
                    <Row>
                      <Col className="col-12" style={{ textAlign: "right" }}>
                        <LanguageDropdown />
                      </Col>
                    </Row>
                    <CardImg
                      src={
                        ROOT_BUCKET +
                        "/config/" +
                        this.props.order.idPlace +
                        "/logo.png"
                      }
                      alt=""
                      style={{
                        backgroundColor: this.props.order.color,
                        maxWidth: "300px",
                      }}
                    />
                  </CardHeader>
                  <CardBody>
                    <div className="pt-3">
                      <div className="row justify-content-center">
                        <div className="col-xl-8">
                          <div>
                            <div className="mt-4">
                              <div className="text-muted font-size-14">
                                <blockquote className="p-4 border-light border rounded mb-4">
                                  <div className="d-flex">
                                    <div className="me-3">
                                      <i className="bx bxs-quote-alt-left text-dark font-size-24"></i>
                                    </div>
                                    <div>
                                      <p className="mb-0">
                                        {" "}
                                        {this.props.order.legend}
                                      </p>
                                    </div>
                                  </div>
                                </blockquote>
                              </div>
                            </div>

                            {this.props.order.isVideo ? (
                              <div>
                                <hr />
                                <div className="ratio ratio-16x9">
                                  <ReactPlayer
                                    className="react-player"
                                    url={
                                      "https://" +
                                      PREFIX +
                                      DOMAIN +
                                      "/order/" +
                                      this.props.order.subfolder +
                                      this.props.order.idComputer +
                                      "/" +
                                      this.props.order.video
                                    }
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <hr />
                            <img
                              src={
                                "https://" +
                                PREFIX +
                                DOMAIN +
                                "/order/" +
                                this.props.order.subfolder +
                                this.props.order.idComputer +
                                "/" +
                                this.props.order.filename +
                                "_8x6.jpg"
                              }
                              alt=""
                              className="img-thumbnail"
                            />
                            {this.props.order.isVideoAd ? (
                              <div>
                                <hr />
                                <div className="ratio ratio-16x9">
                                  <ReactPlayer
                                    className="react-player"
                                    url={
                                      "https://" +
                                      PREFIX +
                                      DOMAIN +
                                      "/" +
                                      this.props.order.videoAd
                                    }
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="text-center">
              <p>
                <a
                  href={this.props.order.url}
                  target="_blank"
                  rel="noreferrer"
                  className="fw-medium"
                  style={{ color: this.props.order.color }}
                >
                  {this.props.order.location} - {this.props.order.url}
                </a>
              </p>
              <p className="text-dark text-opacity-50">
                <i>
                  <Trans i18nKey="landingPage.gdpr"></Trans>{" "}
                  {this.props.order.gdpr !== "-"
                    ? DateTime.fromSQL(this.props.order.gdpr).toFormat("DDDD")
                    : this.props.order.gdpr}
                </i>
              </p>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

LandingPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  order: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  getOrder: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, order } = state.Order;
  return { error, loading, order };
};

export default connect(mapStateToProps, {
  getOrder,
})(withRouter(withTranslation()(LandingPage)));
