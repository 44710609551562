import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { PREFIX, DOMAIN } from "../../helpers/url";

import { Helmet, HelmetProvider } from "react-helmet-async";

// notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//store
import { getPlace, addOrder, cancelOrder } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../assets/scss/custom/plugins/_datatables.scss";

import { DateTime } from "luxon";

import { pricing } from "../Utility/Pricing";

class Place extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sizePerPage: 10,
      currentOpenedPhoto: "",
      isOpenedPhoto: false,
      currentOpenedOrder: {},
      modalCancelIsOpened: false,
      modalAddIsOpened: false,
    };
    this.togModalCancel = this.togModalCancel.bind(this);
    this.togModalAdd = this.togModalAdd.bind(this);
    this.handleDetails = this.handleDetails.bind(this);
    this.handleActions = this.handleActions.bind(this);
    this.attachment1Ref = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params.idPlace > 0) {
      const postData = {
        idPlace: this.props.match.params.idPlace,
        type: "orders",
      };
      this.props.getPlace(postData, this.props.history);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.endorsementAdded !== this.props.endorsementAdded &&
      this.props.endorsementAdded === true
    ) {
      this.setState({ modalAddIsOpened: false });
      if (this.props.match.params.idPlace > 0) {
        const postData = {
          idPlace: this.props.match.params.idPlace,
          type: "orders",
        };
        this.props.getPlace(postData, this.props.history);
      }
    } else if (
      prevProps.orderCancelled !== this.props.orderCancelled &&
      this.props.orderCancelled === true
    ) {
      this.setState({ modalCancelIsOpened: false });
      if (this.props.match.params.idPlace > 0) {
        const postData = {
          idPlace: this.props.match.params.idPlace,
          type: "orders",
        };
        this.props.getPlace(postData, this.props.history);
      }
    }
  }

  togModalAdd() {
    this.setState(prevState => ({
      modalAddIsOpened: !prevState.modalAddIsOpened,
    }));
  }

  togModalCancel() {
    this.setState(prevState => ({
      modalCancelIsOpened: !prevState.modalCancelIsOpened,
    }));
  }

  handleDate(cell) {
    return cell !== "-" ? DateTime.fromSQL(cell).toFormat("DDDD t") : cell;
  }

  handleDetails(cell, row) {
    return (
      <div className="d-flex align-items-center">
        <div className="flex-shrink-0 me-3">
          <img
            onClick={() =>
              this.setState({
                currentOpenedPhoto:
                  "https://" +
                  PREFIX +
                  DOMAIN +
                  "/order/" +
                  row.subfolder +
                  row.idComputer +
                  "/" +
                  row.filename +
                  "_8x6.jpg",
                isOpenedPhoto: true,
              })
            }
            className="rounded me-2 img-thumbnail"
            width="200px"
            src={
              "https://" +
              PREFIX +
              DOMAIN +
              "/order/" +
              row.subfolder +
              row.idComputer +
              "/" +
              row.filename +
              "_8x6.jpg"
            }
          ></img>
        </div>
        <div className="flex-grow-1">
          <h5>
            {row.idOrderParent > 0 ? (
              <span className="text-secondary">
                <Trans i18nKey="place.endorsement"></Trans>
                <br />
                {i18n.t("place.price")} : {row.price}€
              </span>
            ) : (
              <span className="text-primary">
                <Trans i18nKey="place.order"></Trans>
                <br />
                {i18n.t("place.price")} : {row.price}€
              </span>
            )}
          </h5>
          <p className="mb-0">
            <b
              className={
                row.status == "cancelled"
                  ? "text-danger"
                  : row.status == "AskedForCancel"
                  ? "text-warning"
                  : row.status == "printed"
                  ? "text-success"
                  : "text-info"
              }
            >
              {i18n.t("place.orderStatus")} : {i18n.t("place." + row.status)}
            </b>
            <br />
            {i18n.t("place.printStation")} : {cell}{" "}
            {row.computerStillAvailable > 0
              ? ""
              : i18n.t("place.printStationNotAvailable")}
            <br />
            {i18n.t("place.pack")} : {i18n.t("upload." + row.pack + "Pack")}
            <br />
            {i18n.t("place.quantity")} : {row.quantity}{" "}
            {row.endorsementQuantity > 0 ? (
              <span>
                <br />
                {i18n.t("place.quantityViaEndorsement")} :{" "}
                {row.endorsementQuantity}{" "}
              </span>
            ) : (
              ""
            )}
          </p>
        </div>
      </div>
    );
  }

  handleFilterDetails(cell, row) {
    return (
      cell +
      " " +
      i18n.t("upload." + row.pack + "Pack") +
      " " +
      row.quantity +
      " " +
      row.price
    );
  }

  handleActions(cell, row) {
    const actions = false;
    return (
      <div>
        {row.status !== "cancelled" &&
        row.status !== "AskedForCancel" &&
        row.computerStillAvailable > 0 ? (
          <div>
            <Link
              to={cell}
              target="_blank"
              className="btn btn-primary position-relative p-0 avatar-xs rounded-circle"
              title={i18n.t("place.openLandingPage")}
            >
              <span className="avatar-title bg-transparent text-reset">
                <i className="bx bx-link"></i>
              </span>
            </Link>{" "}
            {row.idOrderParent > 0 || !actions ? (
              ""
            ) : (
              <button
                onClick={e => {
                  this.setState({ currentOpenedOrder: row });
                  this.togModalAdd(e);
                }}
                target="_blank"
                className="btn btn-success position-relative p-0 avatar-xs rounded-circle"
                title={i18n.t("place.addToOrder")}
              >
                <span className="avatar-title bg-transparent text-reset">
                  <i className="bx bx-plus"></i>
                </span>
              </button>
            )}{" "}
            {/*<button
              onClick={e => {
                this.setState({ currentOpenedOrder: row });
                this.togModalCancel(e);
              }}
              className="test btn btn-danger position-relative p-0 avatar-xs rounded-circle float-right"
              title={i18n.t("place.cancelOrderDueToIssue")}
            >
              <span className="avatar-title bg-transparent text-reset">
                <i className="bx bx-trash"></i>
              </span>
            </button>*/}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const columns = [
      {
        dataField: "createdAt",
        text: i18n.t("place.dateTime"),
        formatter: this.handleDate,
        sort: true,
        filterValue: this.handleDate,
        searchable: true,
      },
      {
        dataField: "idComputer",
        text: i18n.t("place.details"),
        formatter: this.handleDetails,
        sort: true,
        filterValue: this.handleFilterDetails,
        searchable: true,
      },
      {
        dataField: "landingPage",
        text: i18n.t("place.actions"),
        formatter: this.handleActions,
        sort: false,
        searchable: false,
      },
    ];

    const defaultSorted = [
      {
        dataField: "createdAt",
        order: "desc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize:
        this.props.place &&
        this.props.place.orders &&
        this.props.place.orders.length &&
        this.props.place.orders[0].idOrder !== null
          ? this.props.place.orders.length
          : 0, // replace later with size(customers),
      custom: true,
    };

    // Select All Button operation
    // const selectRow = {
    //   mode: "checkbox",
    // };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (this.props.place && this.props.place.orders
                    ? " : " + this.props.place.orders[0].name
                    : "") +
                  " | Teampix"}
              </title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {this.props.place && this.props.place.orders ? (
              <div>
                <Modal
                  isOpen={this.state.modalAddIsOpened}
                  toggle={this.togModalAdd}
                  centered={true}
                  backdrop="static"
                >
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      quantity: 0,
                      idOrder: this.state.currentOpenedOrder.idOrder || 0,
                    }}
                    validationSchema={Yup.object().shape({
                      quantity: Yup.number()
                        .required(i18n.t("upload.enterQuantity"))
                        .min(1, i18n.t("upload.enterMinQuantity")),
                    })}
                    onSubmit={values => {
                      this.props.addOrder(values, this.props.history);
                    }}
                  >
                    {({
                      handleBlur,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
                      <Form className="form-horizontal">
                        <ModalHeader>
                          <span className="text-success">
                            <Trans i18nKey="place.addTitle"></Trans>
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                modalAddIsOpened: false,
                              })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={this.props.loading}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </ModalHeader>
                        <ModalBody>
                          <div className="mt-3 mb-3">
                            <Trans i18nKey="place.addDescription"></Trans>
                            <div className="mt-3 d-flex align-items-center">
                              <div className="flex-shrink-0 me-3">
                                <img
                                  onClick={() =>
                                    this.setState({
                                      currentOpenedPhoto:
                                        "https://" +
                                        PREFIX +
                                        DOMAIN +
                                        "/order/" +
                                        this.state.currentOpenedOrder
                                          .subfolder +
                                        this.state.currentOpenedOrder
                                          .idComputer +
                                        "/" +
                                        this.state.currentOpenedOrder.filename +
                                        "_8x6.jpg",
                                      isOpenedPhoto: true,
                                    })
                                  }
                                  className="rounded me-2 img-thumbnail"
                                  width="200px"
                                  src={
                                    "https://" +
                                    PREFIX +
                                    DOMAIN +
                                    "/order/" +
                                    this.state.currentOpenedOrder.subfolder +
                                    this.state.currentOpenedOrder.idComputer +
                                    "/" +
                                    this.state.currentOpenedOrder.filename +
                                    "_8x6.jpg"
                                  }
                                ></img>
                              </div>
                              <div className="flex-grow-1">
                                <h5>
                                  {this.state.currentOpenedOrder.idOrderParent >
                                  0 ? (
                                    <span className="text-secondary">
                                      <Trans i18nKey="place.endorsement"></Trans>
                                      <br />
                                      {i18n.t("place.price")} :{" "}
                                      {this.state.currentOpenedOrder.price}€
                                    </span>
                                  ) : (
                                    <span className="text-primary">
                                      <Trans i18nKey="place.order"></Trans>
                                      <br />
                                      {i18n.t("place.price")} :{" "}
                                      {this.state.currentOpenedOrder.price}€
                                    </span>
                                  )}
                                </h5>
                                <p className="mb-0">
                                  <b
                                    className={
                                      this.state.currentOpenedOrder.status ==
                                      "cancelled"
                                        ? "text-danger"
                                        : this.state.currentOpenedOrder
                                            .status == "AskedForCancel"
                                        ? "text-warning"
                                        : this.state.currentOpenedOrder
                                            .status == "printed"
                                        ? "text-success"
                                        : "text-info"
                                    }
                                  >
                                    {i18n.t("place.orderStatus")} :{" "}
                                    {i18n.t(
                                      "place." +
                                        this.state.currentOpenedOrder.status
                                    )}
                                  </b>
                                  <br />
                                  {i18n.t("place.printStation")} :{" "}
                                  {this.state.currentOpenedOrder.idComputer}
                                  <br />
                                  {i18n.t("place.pack")} :{" "}
                                  {i18n.t(
                                    "upload." +
                                      this.state.currentOpenedOrder.pack +
                                      "Pack"
                                  )}
                                  <br />
                                  {i18n.t("place.quantity")} :{" "}
                                  {this.state.currentOpenedOrder.quantity}{" "}
                                  {this.state.currentOpenedOrder
                                    .endorsementQuantity > 0 ? (
                                    <span>
                                      <br />
                                      {i18n.t(
                                        "place.quantityViaEndorsement"
                                      )} :{" "}
                                      {
                                        this.state.currentOpenedOrder
                                          .endorsementQuantity
                                      }{" "}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label for="quantity" className="form-label">
                              <Trans i18nKey={"place.quantityToAdd"} /> *
                            </Label>
                            <Field
                              name="quantity"
                              label={i18n.t("place.yourQuantity")}
                              type="number"
                              disabled={this.props.loading}
                              className={
                                "form-control" +
                                (errors.quantity && touched.quantity
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={e => {
                                const value = e.target.value || "";
                                setFieldValue("quantity", value);
                              }}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="quantity"
                              component="div"
                              className="invalid-feedback"
                            />
                            {values.quantity > 0 ? (
                              <Row className="mt-4">
                                <Col lg={12}>
                                  <Card
                                    outline
                                    color="primary"
                                    className="border mb-0"
                                  >
                                    <CardBody className="p-4">
                                      <div className="text-center">
                                        <div className="avatar-md mx-auto mb-4">
                                          <div className="avatar-title bg-primary rounded-circle text-light h1">
                                            <i className="mdi mdi-information-outline"></i>
                                          </div>
                                        </div>
                                        <Row className="justify-content-center">
                                          <Col xl={10}>
                                            <h4 className="text-primary">
                                              {pricing(
                                                this.state.currentOpenedOrder
                                                  .pack,
                                                parseInt(values.quantity) +
                                                  parseInt(
                                                    this.state
                                                      .currentOpenedOrder
                                                      .quantity
                                                  ) +
                                                  parseInt(
                                                    this.state
                                                      .currentOpenedOrder
                                                      .endorsementQuantity
                                                  ),
                                                this.state.currentOpenedOrder.pricing
                                              ) -
                                                pricing(
                                                  this.state.currentOpenedOrder
                                                    .pack,
                                                  parseInt(
                                                    this.state
                                                      .currentOpenedOrder
                                                      .quantity
                                                  ) +
                                                    parseInt(
                                                      this.state
                                                        .currentOpenedOrder
                                                        .endorsementQuantity
                                                    ),
                                                    this.state.currentOpenedOrder.pricing
                                                )}
                                              €
                                            </h4>
                                            <p className="text-muted font-size-14">
                                              <Trans
                                                i18nKey={
                                                  "place." +
                                                  this.state.currentOpenedOrder
                                                    .pack +
                                                  "PackDescription"
                                                }
                                              ></Trans>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Alert color={"info"}>
                            <i className="fas fa-info-circle"></i>{" "}
                            <Trans i18nKey="place.addDisclaimer"></Trans>
                          </Alert>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.togModalAdd}
                            disabled={this.props.loading}
                          >
                            <Trans i18nKey="place.cancel"></Trans>
                          </button>
                          <button
                            className="btn btn-success"
                            type="submit"
                            disabled={this.props.loading}
                          >
                            <Trans i18nKey="place.confirm"></Trans>
                          </button>
                        </ModalFooter>
                      </Form>
                    )}
                  </Formik>
                </Modal>
                <Modal
                  isOpen={this.state.modalCancelIsOpened}
                  toggle={this.togModalCancel}
                  centered={true}
                  backdrop="static"
                >
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      reason: "",
                      attachment1: null,
                      idOrder: this.state.currentOpenedOrder.idOrder || 0,
                    }}
                    validationSchema={Yup.object().shape({
                      reason: Yup.string().required(
                        i18n.t("place.enterReason")
                      ),
                    })}
                    onSubmit={values => {
                      this.props.cancelOrder(values, this.props.history);
                    }}
                  >
                    {({
                      handleBlur,
                      setFieldValue,
                      values,
                      errors,
                      touched,
                    }) => (
                      <Form className="form-horizontal">
                        <ModalHeader>
                          <span className="text-danger">
                            <Trans i18nKey="place.areYouSureToCancelTitle"></Trans>
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                modalCancelIsOpened: false,
                              })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={this.props.loading}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </ModalHeader>
                        <ModalBody>
                          <div>
                            <Trans i18nKey="place.areYouSureToCancelDescription"></Trans>
                            <div className="mt-3 d-flex align-items-center">
                              <div className="flex-shrink-0 me-3">
                                <img
                                  onClick={() =>
                                    this.setState({
                                      currentOpenedPhoto:
                                        "https://" +
                                        PREFIX +
                                        DOMAIN +
                                        "/order/" +
                                        this.state.currentOpenedOrder
                                          .subfolder +
                                        this.state.currentOpenedOrder
                                          .idComputer +
                                        "/" +
                                        this.state.currentOpenedOrder.filename +
                                        "_8x6.jpg",
                                      isOpenedPhoto: true,
                                    })
                                  }
                                  className="rounded me-2 img-thumbnail"
                                  width="200px"
                                  src={
                                    "https://" +
                                    PREFIX +
                                    DOMAIN +
                                    "/order/" +
                                    this.state.currentOpenedOrder.subfolder +
                                    this.state.currentOpenedOrder.idComputer +
                                    "/" +
                                    this.state.currentOpenedOrder.filename +
                                    "_8x6.jpg"
                                  }
                                ></img>
                              </div>
                              <div className="flex-grow-1">
                                <h5>
                                  {this.state.currentOpenedOrder.idOrderParent >
                                  0 ? (
                                    <span className="text-secondary">
                                      <Trans i18nKey="place.endorsement"></Trans>
                                      <br />
                                      {i18n.t("place.price")} :{" "}
                                      {this.state.currentOpenedOrder.price}€
                                    </span>
                                  ) : (
                                    <span className="text-primary">
                                      <Trans i18nKey="place.order"></Trans>
                                      <br />
                                      {i18n.t("place.price")} :{" "}
                                      {this.state.currentOpenedOrder.price}€
                                    </span>
                                  )}
                                </h5>
                                <p className="mb-0">
                                  <b
                                    className={
                                      this.state.currentOpenedOrder.status ==
                                      "cancelled"
                                        ? "text-danger"
                                        : this.state.currentOpenedOrder
                                            .status == "AskedForCancel"
                                        ? "text-warning"
                                        : this.state.currentOpenedOrder
                                            .status == "printed"
                                        ? "text-success"
                                        : "text-info"
                                    }
                                  >
                                    {i18n.t("place.orderStatus")} :{" "}
                                    {i18n.t(
                                      "place." +
                                        this.state.currentOpenedOrder.status
                                    )}
                                  </b>
                                  <br />
                                  {i18n.t("place.printStation")} :{" "}
                                  {this.state.currentOpenedOrder.idComputer}
                                  <br />
                                  {i18n.t("place.pack")} :{" "}
                                  {i18n.t(
                                    "upload." +
                                      this.state.currentOpenedOrder.pack +
                                      "Pack"
                                  )}
                                  <br />
                                  {i18n.t("place.quantity")} :{" "}
                                  {this.state.currentOpenedOrder.quantity}{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label for="reason" className="form-label">
                              <Trans i18nKey="place.reason" /> *
                            </Label>
                            <Field
                              name="reason"
                              placeholder={i18n.t("place.yourReason")}
                              as="textarea"
                              rows="3"
                              disabled={this.props.loading}
                              className={
                                "form-control" +
                                (errors.reason && touched.reason
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={e => {
                                const value = e.target.value || "";
                                setFieldValue("reason", value);
                              }}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="reason"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3">
                            <Label for="attachment1" className="form-label">
                              <Trans i18nKey="place.attachment1" />
                            </Label>
                            <div className="input-group">
                              <input
                                ref={this.attachment1Ref}
                                name="attachment1"
                                type="file"
                                disabled={this.props.loading}
                                // accept={PHOTO_FILE_TYPE.join(",")}
                                className={
                                  "form-control" +
                                  (errors.attachment1 && touched.attachment1
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={e => {
                                  const value = e.target.files[0] || null;
                                  setFieldValue("attachment1", value);
                                }}
                                onBlur={handleBlur}
                              />
                              {values.attachment1 !== null ? (
                                <Button
                                  color="danger"
                                  type="button"
                                  onClick={() => {
                                    this.attachment1Ref.current.value = null;
                                    setFieldValue("attachment1", null);
                                  }}
                                  disabled={this.props.loading}
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                            <ErrorMessage
                              name="attachment1"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Alert color={"danger"}>
                            <i className="fas fa-exclamation-triangle"></i>{" "}
                            <Trans i18nKey="place.areYouSureToCancelDisclaimer"></Trans>
                          </Alert>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.togModalCancel}
                            disabled={this.props.loading}
                          >
                            <Trans i18nKey="place.cancel"></Trans>
                          </button>
                          <button
                            className="btn btn-danger"
                            type="submit"
                            disabled={this.props.loading}
                          >
                            <Trans i18nKey="place.confirm"></Trans>
                          </button>
                        </ModalFooter>
                      </Form>
                    )}
                  </Formik>
                </Modal>
                {this.state.isOpenedPhoto && (
                  <Lightbox
                    mainSrc={this.state.currentOpenedPhoto}
                    // nextSrc={images[(photoIndex + 1) % images.length]}
                    // prevSrc={
                    //   images[(photoIndex + images.length - 1) % images.length]
                    // }
                    enableZoom={false}
                    onCloseRequest={() =>
                      this.setState({
                        isOpenedPhoto: false,
                        currentOpenedPhoto: "",
                      })
                    }
                    // onMovePrevRequest={() =>
                    //   this.setState({
                    //     photoIndex:
                    //       (photoIndex + images.length - 1) % images.length,
                    //   })
                    // }
                    // onMoveNextRequest={() =>
                    //   this.setState({
                    //     photoIndex: (photoIndex + 1) % images.length,
                    //   })
                    // }
                  />
                )}
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        <div className="p-2 mb-5">
                          <h5 className="mt-3 mb-3">
                            <Trans i18nKey="place.orders" />
                          </h5>

                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="idOrder"
                            columns={columns}
                            data={
                              this.props.place.orders &&
                              this.props.place.orders[0].idOrder !== null
                                ? this.props.place.orders
                                : []
                            }
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="idOrder"
                                columns={columns}
                                data={
                                  this.props.place.orders &&
                                  this.props.place.orders[0].idOrder !== null
                                    ? this.props.place.orders
                                    : []
                                }
                                search
                                bootstrap4
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col md="4">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                              placeholder={i18n.t(
                                                "place.search"
                                              )}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField={"idOrder"}
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            hover={true}
                                            defaultSorted={defaultSorted}
                                            // selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                            noDataIndication={
                                              <Trans i18nKey="place.emptyListOrders"></Trans>
                                            }
                                          />
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                      <Col className="inner-custom-pagination d-flex">
                                        {
                                          // <div className="d-inline">
                                          //   <SizePerPageDropdownStandalone
                                          //     {...paginationProps}
                                          //   />
                                          // </div>
                                        }
                                        <div className="text-md-right ms-auto">
                                          <div className="pagination pagination-rounded justify-content-end">
                                            <PaginationListStandalone
                                              {...paginationProps}
                                            />
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                          <br />
                          <br />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Place.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  getPlace: PropTypes.func,
  addOrder: PropTypes.func,
  cancelOrder: PropTypes.func,
  endorsementAdded: PropTypes.bool,
  orderCancelled: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, loading, place, endorsementAdded, orderCancelled } =
    state.Place;
  return { error, loading, place, endorsementAdded, orderCancelled };
};

export default connect(mapStateToProps, {
  getPlace,
  addOrder,
  cancelOrder,
})(withRouter(withTranslation()(Place)));
