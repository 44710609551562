import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { GET_ORDER, LIST_ORDERS, LAST_ORDERS } from "./actionTypes";
import {
  getOrderSuccess,
  getOrderFail,
  listOrdersSuccess,
  listOrdersFail,
  lastOrdersSuccess,
  lastOrdersFail,
} from "./actions";

//Include Both Helper File with needed methods
import { getOrderCall, listOrdersCall, lastOrdersCall } from "../../helpers/backend";

function* getOrder({ payload: { order, history } }) {
  try {
    const response = yield call(getOrderCall, order);

    if (response.status === "success") {
      yield put(getOrderSuccess(response));
    } else {
      yield put(getOrderFail(response));
    }
  } catch (error) {
    yield put(getOrderFail(error));
    if (error === "backend.invalidCredentials") history.push("/logout");
  }
}

function* listOrders({ payload: { history } }) {
  try {
    const response = yield call(listOrdersCall);
    if (response.status === "success") {
      yield put(listOrdersSuccess(response));
    } else {
      yield put(listOrdersFail(response));
    }
  } catch (error) {
    yield put(listOrdersFail(error));
    if (error === "backend.invalidCredentials") history.push("/logout");
  }
}

function* lastOrders({ payload: { history } }) {
  try {
    const response = yield call(lastOrdersCall);
    if (response.status === "success") {
      yield put(lastOrdersSuccess(response));
    } else {
      yield put(lastOrdersFail(response));
    }
  } catch (error) {
    yield put(lastOrdersFail(error));
    if (error === "backend.invalidCredentials") history.push("/logout");
  }
}

export function* watchOrder() {
  yield takeEvery(GET_ORDER, getOrder);
  yield takeEvery(LIST_ORDERS, listOrders);
  yield takeEvery(LAST_ORDERS, lastOrders);
}

function* OrderSaga() {
  yield all([fork(watchOrder)]);
}

export default OrderSaga;
