import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// Public pages
import Upload from "../pages/Public/Upload/Upload";
import LandingPage from "../pages/Public/LandingPage/LandingPage";
import Help from "../pages/Public/Help/Help";
import ShowCase from "../pages/Public/ShowCase/ShowCase";

// Clients pages
import Home from "../pages/Clients/index";
import Settings from "../pages/Clients/Settings";
import PlaceOrders from "../pages/Clients/Orders";
import PlaceHardware from "../pages/Clients/Hardware";
import PlaceInvoicing from "../pages/Clients/Invoicing";
import PlaceStripe from "../pages/Clients/Stripe";
import ClientProfile from "../pages/Clients/Profile";

// Ops pages
import Dashboard from "../pages/Ops/Dashboard";
import Places from "../pages/Ops/Places";
import Hardware from "../pages/Ops/Hardware";
import Users from "../pages/Ops/Users";
import Orders from "../pages/Ops/Orders";
import OpsProfile from "../pages/Ops/Profile";

// Utility pages
import Page404 from "../pages/Utility/Page404";

const authInternalProtectedRoutes = [
  { path: "/profile", component: OpsProfile },
  { path: "/dashboard", component: Dashboard },
  { path: "/places", component: Places },
  { path: "/hardware", component: Hardware },
  { path: "/users", component: Users },
  { path: "/orders", component: Orders },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const authProtectedRoutes = [
  { path: "/account", component: ClientProfile },
  { path: "/place/:idPlace", component: Home },
  { path: "/place/:idPlace/settings", component: Settings },
  { path: "/place/:idPlace/hardware", component: PlaceHardware },
  { path: "/place/:idPlace/orders", component: PlaceOrders },
  { path: "/place/:idPlace/invoicing", component: PlaceInvoicing },
  { path: "/place/:idPlace/stripe/:type", component: PlaceStripe },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/login" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/notFound", component: Page404 },
  { path: "/upload/:idComputer", component: Upload },
  {
    path: "/public/:idPlace/:idComputer/:idOrder/:token",
    component: LandingPage,
  },
];

const showCaseRoutes = [
  { path: "/", component: ShowCase },
  {
    path: "*",
    component: () => <Redirect to="/" />,
  },
];

const publicHelpRoutes = [
  { path: "/notFound", component: Page404 },
  { path: "/:idComputer", component: Help },
  { path: "/", component: Help },
  // this route should be at the end of all other routes
  {
    path: "*",
    component: () => <Redirect to="/" />,
  },
];

export {
  authInternalProtectedRoutes,
  authProtectedRoutes,
  publicRoutes,
  publicHelpRoutes,
  showCaseRoutes,
};
