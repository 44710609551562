import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Progress,
} from "reactstrap";
import { ROOT_BUCKET } from "../../../helpers/url";

import classnames from "classnames";

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Helmet, HelmetProvider } from "react-helmet-async";

import Preview from "./Preview";
import EXIF from "exif-js";

//i18n
import i18n from "../../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LanguageDropdown from "../../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

//store
import { getUpload, connectUpload, submitUpload } from "../../../store/actions";

// design
import profileImg from "../../../assets/images/card/upload.png";
import logoImg from "../../../assets/images/card/home.png";
import ReactPlayer from "react-player";

// file type
import {
  PHOTO_FILE_SIZE,
  VIDEO_FILE_SIZE,
  PHOTO_FILE_TYPE,
  SAFEGUARD_MAX_QUANTITY,
} from "../../../helpers/files";

import { DateTime } from "luxon";

import { pricing } from "../../Utility/Pricing";

// const Step1Schema = Yup.object().shape({
//   legend: Yup.string()
//     .required(i18n.t("upload.enterLegend"))
//     .min(10, i18n.t("upload.enterMinLegend"))
//     .max(50, i18n.t("upload.enterMaxLegend")),
// });
const Step2Schema = Yup.object().shape({
  photo: Yup.mixed()
    .required(i18n.t("upload.choosePhoto"))
    .test("fileFormat", i18n.t("upload.JPEGOnly"), value => {
      return value && PHOTO_FILE_TYPE.includes(value.type);
    })
    .test(
      "fileSize",
      i18n.t("upload.photoTooLarge"),
      value => value && value.size <= PHOTO_FILE_SIZE
    ),
  previewLargeSize: Yup.string().required(i18n.t("upload.choosePhoto")),
  previewSmallSize: Yup.string().required(i18n.t("upload.choosePhoto")),
});
const Step3Schema = Yup.object().shape({
  video: Yup.mixed()
    .test("fileFormat", i18n.t("upload.MP4MOVOnly"), value => {
      return value
        ? ["video/mp4", "video/quicktime"].includes(value.type)
        : true;
    })
    .test("fileSize", i18n.t("upload.videoTooLarge"), value => {
      return value ? value.size <= VIDEO_FILE_SIZE : true;
    }),
});
const Step4Schema = Yup.object().shape({
  pack: Yup.string().required(i18n.t("upload.enterPack")),
  quantity: Yup.number()
    .required(i18n.t("upload.enterQuantity"))
    .min(1, i18n.t("upload.enterMinQuantity")),
});

const Step5Schema = Yup.object().shape({
  pin: Yup.string()
    .trim()
    .matches(/^[0-9]{4}$/, i18n.t("upload.enterValidPin"))
    .required(i18n.t("upload.enterPin")),
});

const schemaArray = [
  // Step1Schema,
  Step2Schema,
  Step3Schema,
  Step4Schema,
  Step5Schema,
];

const fixedQuantityPacks = ["solo", "family", "group"];

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      previewLargeSize: false,
      previewSmallSize: false,
      modal_center: false,
      activeTab: 1,
      passedSteps: [1],
    };
    this.tog_center = this.tog_center.bind(this);
    this.toggleTab.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.idComputer > 0) {
      const postData = {
        idComputer: this.props.match.params.idComputer,
      };
      this.props.getUpload(postData, this.props.history);
    }
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= schemaArray.length) {
        var modifiedSteps = [...this.state.passedSteps, tab];
        this.setState({
          activeTab: tab,
          passedSteps: modifiedSteps,
        });
      }
    }
  }

  tog_center() {
    this.setState(prevState => ({
      modal_center: !prevState.modal_center,
    }));
  }

  render() {
    const favicon = document.querySelector("link[class='favicon']");
    if (this.props.upload)
      favicon.setAttribute(
        "href",
        ROOT_BUCKET + "/config/" + this.props.upload.idPlace + "/icon.png"
      );

    return (
      <React.Fragment>
        <div
          className="page-content"
          style={{ paddingTop: "20px", paddingBottom: "20px" }}
        >
          <HelmetProvider>
            <Helmet>
              <title>
                {(this.props.upload ? this.props.upload.place + " - " : "") +
                  i18n.t("upload.title") +
                  " | Teampix"}
              </title>
            </Helmet>
          </HelmetProvider>
          <div className="justify-content-center">
            <Container fluid>
              {this.props.done ? (
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={6}>
                    <Card>
                      <CardBody>
                        <div className="p-2">
                          <div className="text-center">
                            <div className="avatar-md mx-auto">
                              <div className="avatar-title rounded-circle bg-light">
                                <i
                                  className="bx bx bx-printer h1 mb-0"
                                  style={{
                                    backgroundColor: this.props.upload.color,
                                  }}
                                ></i>
                              </div>
                            </div>
                            <div className="p-2 mt-4">
                              <h4>
                                <Trans i18nKey="upload.success"></Trans>
                              </h4>
                              <p className="text-muted">
                                <Trans i18nKey="upload.printingInProgress"></Trans>
                              </p>
                              <div className="mt-4">
                                <button
                                  onClick={() => window.location.reload(false)}
                                  className="btn btn-primary"
                                >
                                  <Trans i18nKey="upload.oneMore"></Trans>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : this.props.upload ? (
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={6}>
                    <Card className="overflow-hidden">
                      <div
                        style={{
                          backgroundColor: this.props.upload.color + "80",
                        }}
                      >
                        <Row>
                          <Col
                            className="col-12"
                            style={{ textAlign: "right" }}
                          >
                            <LanguageDropdown />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-7">
                            <div
                              className="p-4"
                              style={{ color: this.props.upload.color }}
                            >
                              <h5 style={{ color: this.props.upload.color }}>
                                <Trans i18nKey="upload.title" />
                              </h5>
                              <p>{this.props.upload.place}</p>
                            </div>
                          </Col>
                          <Col className="col-5 align-self-end">
                            <img
                              src={profileImg}
                              alt=""
                              className="img-fluid"
                            />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div className="auth-logo">
                          <Link
                            to={
                              this.props.loading || this.state.processing
                                ? "#"
                                : "/upload/" +
                                  this.props.match.params.idComputer
                            }
                            className="auth-logo-dark"
                          >
                            <div className="avatar-md profile-user-wid mb-4">
                              <span
                                className="avatar-title rounded-circle "
                                style={{
                                  backgroundColor: this.props.upload.color,
                                }}
                              >
                                <img
                                  src={
                                    ROOT_BUCKET +
                                    "/config/" +
                                    this.props.upload.idPlace +
                                    "/icon.png"
                                  }
                                  alt=""
                                  height="34"
                                />
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Formik
                            enableReinitialize={false}
                            initialValues={{
                              idComputer:
                                this.props.match.params.idComputer || 0,
                              pin: this.props.upload.pin || "",
                              photo: null,
                              video: null,
                              legend:
                                this.props.upload.defaultLegend
                                  .replace(
                                    "{date}",
                                    DateTime.now().toLocaleString(
                                      DateTime.DATE_SHORT
                                    )
                                  )
                                  .replace(
                                    "{place}",
                                    this.props.upload.place
                                  ) ||
                                DateTime.now().toLocaleString(
                                  DateTime.DATE_SHORT
                                ) +
                                  " - " +
                                  this.props.upload.place,
                              previewVideo: null,
                              exif: null,
                              previewSmallSize: "",
                              quantity: this.props.upload.defaultQuantity || 0,
                              previewLargeSize: "",
                              pack: this.props.upload.defaultPack || "",
                            }}
                            validationSchema={
                              schemaArray[this.state.activeTab - 1]
                            }
                            onSubmit={values => {
                              if (this.state.activeTab !== schemaArray.length) {
                                this.toggleTab(this.state.activeTab + 1);
                              } else {
                                // ajout d'un popup en cas d'upload suppérieur à SAFEGUARD_MAX_QUANTITY
                                if (
                                  values.quantity > SAFEGUARD_MAX_QUANTITY ||
                                  values.video === null
                                ) {
                                  this.tog_center();
                                } else {
                                  const legend =
                                    this.props.upload.defaultLegend
                                      .replace(
                                        "{date}",
                                        DateTime.now().toLocaleString(
                                          DateTime.DATE_SHORT
                                        )
                                      )
                                      .replace(
                                        "{place}",
                                        this.props.upload.place
                                      ) ||
                                    DateTime.now().toLocaleString(
                                      DateTime.DATE_SHORT
                                    ) +
                                      " - " +
                                      this.props.upload.place;
                                  const valuesToSend = {
                                    ...values,
                                    legend,
                                  };

                                  this.props.submitUpload(
                                    valuesToSend,
                                    this.props.history
                                  );
                                }
                              }
                            }}
                          >
                            {({
                              handleBlur,
                              setFieldValue,
                              values,
                              errors,
                              touched,
                            }) => (
                              <Form className="form-horizontal">
                                <div className="wizard clearfix">
                                  <div className="steps clearfix">
                                    <ul>
                                      {
                                        // <NavItem
                                        //   className={classnames({
                                        //     current: this.state.activeTab === 1,
                                        //     hidden: this.state.activeTab !== 1,
                                        //   })}
                                        // >
                                        //   <NavLink
                                        //     disabled={true}
                                        //     className={
                                        //       classnames({
                                        //         active:
                                        //           this.state.activeTab === 1,
                                        //       })
                                        //     }
                                        // style={{color: this.props.upload.color}}
                                        //     onClick={() => {
                                        //       this.toggleTab(1);
                                        //     }}
                                        //   >
                                        //     <span className="number">1/4</span>{" "}
                                        //     <Trans i18nKey="upload.step1"></Trans>{" "}
                                        //     :{" "}
                                        //     <Trans i18nKey="upload.legend"></Trans>
                                        //   </NavLink>
                                        // </NavItem>
                                      }
                                      <NavItem
                                        className={classnames({
                                          current: this.state.activeTab === 1,
                                          hidden: this.state.activeTab !== 1,
                                        })}
                                      >
                                        <NavLink
                                          disabled={
                                            true
                                            // !(
                                            //   this.state.passedSteps || []
                                            // ).includes(2)
                                          }
                                          className={classnames({
                                            active: this.state.activeTab === 1,
                                          })}
                                          style={{
                                            color: this.props.upload.color,
                                            backgroundColor:
                                              this.props.upload.color + "80",
                                          }}
                                          // onClick={() => {
                                          //   this.toggleTab(2);
                                          // }}
                                        >
                                          <span
                                            className="number"
                                            style={{
                                              backgroundColor:
                                                this.props.upload.color,
                                              borderColor:
                                                this.props.upload.color,
                                            }}
                                          >
                                            1/4
                                          </span>
                                          <Trans i18nKey="upload.step1"></Trans>{" "}
                                          :{" "}
                                          <Trans i18nKey="upload.photo"></Trans>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem
                                        className={classnames({
                                          current: this.state.activeTab === 2,
                                          hidden: this.state.activeTab !== 2,
                                        })}
                                      >
                                        <NavLink
                                          disabled={
                                            true
                                            // !(
                                            //   this.state.passedSteps || []
                                            // ).includes(3)
                                          }
                                          className={
                                            (classnames({
                                              active:
                                                this.state.activeTab === 2,
                                            }),
                                            "done")
                                          }
                                          style={{
                                            color: this.props.upload.color,
                                            backgroundColor:
                                              this.props.upload.color + "80",
                                          }}
                                          // onClick={() => {
                                          //   this.toggleTab(3);
                                          // }}
                                        >
                                          <span
                                            className="number"
                                            style={{
                                              backgroundColor:
                                                this.props.upload.color,
                                              borderColor:
                                                this.props.upload.color,
                                            }}
                                          >
                                            2/4
                                          </span>
                                          <Trans i18nKey="upload.step2"></Trans>{" "}
                                          :{" "}
                                          <Trans i18nKey="upload.video"></Trans>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem
                                        className={classnames({
                                          current: this.state.activeTab === 3,
                                          hidden: this.state.activeTab !== 3,
                                        })}
                                      >
                                        <NavLink
                                          disabled={
                                            true
                                            // !(
                                            //   this.state.passedSteps || []
                                            // ).includes(3)
                                          }
                                          className={
                                            (classnames({
                                              active:
                                                this.state.activeTab === 3,
                                            }),
                                            "done")
                                          }
                                          style={{
                                            color: this.props.upload.color,
                                            backgroundColor:
                                              this.props.upload.color + "80",
                                          }}
                                          // onClick={() => {
                                          //   this.toggleTab(3);
                                          // }}
                                        >
                                          <span
                                            className="number"
                                            style={{
                                              backgroundColor:
                                                this.props.upload.color,
                                              borderColor:
                                                this.props.upload.color,
                                            }}
                                          >
                                            3/4
                                          </span>
                                          <Trans i18nKey="upload.step3"></Trans>{" "}
                                          :{" "}
                                          <Trans i18nKey="upload.pack"></Trans>
                                        </NavLink>
                                      </NavItem>
                                      <NavItem
                                        className={classnames({
                                          current:
                                            this.state.activeTab ===
                                            schemaArray.length,
                                          hidden:
                                            this.state.activeTab !==
                                            schemaArray.length,
                                        })}
                                      >
                                        <NavLink
                                          disabled={
                                            true
                                            // !(
                                            //   this.state.passedSteps || []
                                            // ).includes(4)
                                          }
                                          className={
                                            (classnames({
                                              active:
                                                this.state.activeTab ===
                                                schemaArray.length,
                                            }),
                                            "done")
                                          }
                                          style={{
                                            color: this.props.upload.color,
                                            backgroundColor:
                                              this.props.upload.color + "80",
                                          }}
                                          // onClick={() => {
                                          //   this.toggleTab(4);
                                          // }}
                                        >
                                          <span
                                            className="number"
                                            style={{
                                              backgroundColor:
                                                this.props.upload.color,
                                              borderColor:
                                                this.props.upload.color,
                                            }}
                                          >
                                            4/4
                                          </span>
                                          <Trans i18nKey="upload.step4"></Trans>{" "}
                                          :{" "}
                                          <Trans i18nKey="upload.validate"></Trans>
                                        </NavLink>
                                      </NavItem>
                                    </ul>
                                  </div>
                                  <div className="content clearfix">
                                    <TabContent
                                      activeTab={this.state.activeTab}
                                      className="body"
                                    >
                                      {
                                        // <TabPane tabId={1}>
                                        //   <div className="mb-3">
                                        //     <Label
                                        //       for="legend"
                                        //       className="form-label"
                                        //     >
                                        //       <Trans i18nKey="upload.legend" />{" "}
                                        //       *
                                        //     </Label>
                                        //     <Field
                                        //       name="legend"
                                        //       label={i18n.t(
                                        //         "upload.yourLegend"
                                        //       )}
                                        //       type="text"
                                        //       disabled={
                                        //         this.props.loading ||
                                        //         this.state.processing
                                        //       }
                                        //       className={
                                        //         "form-control" +
                                        //         (errors.legend && touched.legend
                                        //           ? " is-invalid"
                                        //           : "")
                                        //       }
                                        //       onChange={e => {
                                        //         const value =
                                        //           e.target.value || "";
                                        //         setFieldValue("legend", value);
                                        //       }}
                                        //       onBlur={handleBlur}
                                        //     />
                                        //     <ErrorMessage
                                        //       name="legend"
                                        //       component="div"
                                        //       className="invalid-feedback"
                                        //     />
                                        //   </div>
                                        // </TabPane>
                                      }

                                      <TabPane tabId={1}>
                                        <div className="mb-3">
                                          <Label
                                            for="photo"
                                            className="form-label"
                                          >
                                            <Trans i18nKey="upload.photo" /> *
                                          </Label>
                                          <input
                                            name="photo"
                                            type="file"
                                            disabled={
                                              this.props.loading ||
                                              this.state.processing
                                            }
                                            accept={PHOTO_FILE_TYPE.join(",")}
                                            className={
                                              "form-control" +
                                              (errors.photo && touched.photo
                                                ? " is-invalid"
                                                : "")
                                            }
                                            onChange={e => {
                                              const value =
                                                e.target.files[0] || null;

                                              if (
                                                value !== null &&
                                                PHOTO_FILE_TYPE.includes(
                                                  value.type
                                                )
                                              ) {
                                                this.setState({
                                                  processing: true,
                                                  previewSmallSize: false,
                                                  previewLargeSize: false,
                                                });
                                                EXIF.getData(
                                                  value,
                                                  function () {
                                                    setFieldValue(
                                                      "exif",
                                                      EXIF.getAllTags(this)
                                                    );
                                                    setFieldValue(
                                                      "photo",
                                                      value
                                                    );
                                                  }
                                                );
                                              } else {
                                                setFieldValue("photo", value);
                                              }
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <ErrorMessage
                                            name="photo"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        <Row>
                                          <Col sm={7}>
                                            <div className="mb-3">
                                              <Preview
                                                config={
                                                  this.props.upload.configLarge
                                                }
                                                size="Large"
                                                idPlace={
                                                  this.props.upload.idPlace
                                                }
                                                color={this.props.upload.color}
                                                photo={values.photo}
                                                exif={values.exif}
                                                text={
                                                  this.props.upload.defaultLegend
                                                    .replace(
                                                      "{date}",
                                                      DateTime.now().toLocaleString(
                                                        DateTime.DATE_SHORT
                                                      )
                                                    )
                                                    .replace(
                                                      "{place}",
                                                      this.props.upload.place
                                                    ) ||
                                                  DateTime.now().toLocaleString(
                                                    DateTime.DATE_SHORT
                                                  ) +
                                                    " - " +
                                                    this.props.upload.place
                                                }
                                                handlePreview={res => {
                                                  const value = res || "";
                                                  setFieldValue(
                                                    "previewLargeSize",
                                                    value
                                                  );
                                                  this.setState({
                                                    previewLargeSize: true,
                                                    processing:
                                                      !this.state
                                                        .previewSmallSize &&
                                                      this.state.processing,
                                                  });
                                                }}
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={5}>
                                            <div className="mb-3">
                                              <Preview
                                                config={
                                                  this.props.upload.configSmall
                                                }
                                                size="Small"
                                                idPlace={
                                                  this.props.upload.idPlace
                                                }
                                                color={this.props.upload.color}
                                                photo={values.photo}
                                                exif={values.exif}
                                                text={
                                                  this.props.upload.defaultLegend
                                                    .replace(
                                                      "{date}",
                                                      DateTime.now().toLocaleString(
                                                        DateTime.DATE_SHORT
                                                      )
                                                    )
                                                    .replace(
                                                      "{place}",
                                                      this.props.upload.place
                                                    ) ||
                                                  DateTime.now().toLocaleString(
                                                    DateTime.DATE_SHORT
                                                  ) +
                                                    " - " +
                                                    this.props.upload.place
                                                }
                                                handlePreview={res => {
                                                  const value = res || "";
                                                  setFieldValue(
                                                    "previewSmallSize",
                                                    value
                                                  );
                                                  this.setState({
                                                    previewSmallSize: true,
                                                    processing:
                                                      !this.state
                                                        .previewLargeSize &&
                                                      this.state.processing,
                                                  });
                                                }}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                      </TabPane>
                                      <TabPane tabId={2}>
                                        <div className="mb-3">
                                          <Label
                                            for="video"
                                            className="form-label"
                                          >
                                            <Trans i18nKey="upload.videoOptional" />
                                          </Label>
                                          <input
                                            name="video"
                                            type="file"
                                            disabled={
                                              this.props.loading ||
                                              this.state.processing
                                            }
                                            accept="video/mp4,video/quicktime"
                                            className={
                                              "form-control" +
                                              (errors.video && touched.video
                                                ? " is-invalid"
                                                : "")
                                            }
                                            onChange={e => {
                                              const value =
                                                e.target.files[0] || null;
                                              setFieldValue("video", value);
                                              if (value !== null) {
                                                let reader = new FileReader();
                                                reader.onloadend = () => {
                                                  setFieldValue(
                                                    "previewVideo",
                                                    reader.result
                                                  );
                                                };
                                                reader.readAsDataURL(value);
                                              } else {
                                                setFieldValue(
                                                  "previewVideo",
                                                  null
                                                );
                                              }
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <ErrorMessage
                                            name="video"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        {values.previewVideo !== null &&
                                        values.previewVideo.startsWith(
                                          "data:video/mp4;"
                                        ) ? (
                                          <div className="ratio ratio-16x9">
                                            <ReactPlayer
                                              className="react-player"
                                              url={values.previewVideo}
                                              width="100%"
                                              height="100%"
                                              controls={true}
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </TabPane>
                                      <TabPane tabId={3}>
                                        <div className="mb-3">
                                          <Label
                                            for="pack"
                                            className="form-label"
                                          >
                                            <Trans i18nKey="upload.pack" /> *
                                          </Label>

                                          <Field
                                            name="pack"
                                            as="select"
                                            disabled={
                                              this.props.loading ||
                                              this.state.processing
                                            }
                                            className={
                                              "form-control" +
                                              (errors.pack && touched.pack
                                                ? " is-invalid"
                                                : "")
                                            }
                                            onChange={e => {
                                              if (
                                                fixedQuantityPacks.includes(
                                                  e.target.value
                                                )
                                              ) {
                                                if (e.target.value === "solo")
                                                  setFieldValue("quantity", 1);
                                                else if (
                                                  e.target.value === "family"
                                                )
                                                  setFieldValue("quantity", 11);
                                                else
                                                  setFieldValue("quantity", 21);
                                              }
                                              setFieldValue(
                                                "pack",
                                                e.target.value
                                              );
                                            }}
                                          >
                                            <option
                                              value=""
                                              disabled={true}
                                              className={"text-disabled"}
                                            >
                                              {i18n.t("upload.choosePack")}
                                            </option>
                                            {this.props.upload.packList.indexOf(
                                              "standard"
                                            ) === -1 ? (
                                              ""
                                            ) : (
                                              <option
                                                value="standard"
                                                key={"standard"}
                                              >
                                                {i18n.t("upload.standardPack")}
                                              </option>
                                            )}
                                            {this.props.upload.packList.indexOf(
                                              "birthday"
                                            ) === -1 ? (
                                              ""
                                            ) : (
                                              <option
                                                value="birthday"
                                                key={"birthday"}
                                              >
                                                {i18n.t("upload.birthdayPack")}
                                              </option>
                                            )}
                                            {this.props.upload.packList.indexOf(
                                              "premium"
                                            ) === -1 ? (
                                              ""
                                            ) : (
                                              <option
                                                value="premium"
                                                key={"premium"}
                                              >
                                                {i18n.t("upload.premiumPack")}
                                              </option>
                                            )}
                                            {this.props.upload.packList.indexOf(
                                              "solo"
                                            ) === -1 ? (
                                              ""
                                            ) : (
                                              <option value="solo" key={"solo"}>
                                                {i18n.t("upload.soloPack")}
                                              </option>
                                            )}
                                            {this.props.upload.packList.indexOf(
                                              "family"
                                            ) === -1 ? (
                                              ""
                                            ) : (
                                              <option
                                                value="family"
                                                key={"family"}
                                              >
                                                {i18n.t("upload.familyPack")}
                                              </option>
                                            )}
                                            {this.props.upload.packList.indexOf(
                                              "group"
                                            ) === -1 ? (
                                              ""
                                            ) : (
                                              <option
                                                value="group"
                                                key={"group"}
                                              >
                                                {i18n.t("upload.groupPack")}
                                              </option>
                                            )}
                                          </Field>
                                          <ErrorMessage
                                            name="pack"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>

                                        {values.pack !== 0 ? (
                                          <div className="mb-3">
                                            <Label
                                              for="quantity"
                                              className="form-label"
                                            >
                                              <Trans
                                                i18nKey={
                                                  "upload." +
                                                  values.pack +
                                                  "PackQuantity"
                                                }
                                              />{" "}
                                              *
                                            </Label>
                                            <Field
                                              name="quantity"
                                              label={i18n.t(
                                                "upload.yourQuantity"
                                              )}
                                              as="select"
                                              disabled={
                                                this.props.loading ||
                                                this.state.processing ||
                                                fixedQuantityPacks.includes(
                                                  values.pack
                                                )
                                              }
                                              className={
                                                "form-control" +
                                                (errors.quantity &&
                                                touched.quantity
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                              onChange={e => {
                                                const value =
                                                  e.target.value || "";
                                                setFieldValue(
                                                  "quantity",
                                                  value
                                                );
                                              }}
                                              onBlur={handleBlur}
                                            >
                                              {[...Array(50)].map((x, i) => (
                                                <option
                                                  key={i + 1}
                                                  value={i + 1}
                                                >
                                                  {i + 1}
                                                </option>
                                              ))}
                                            </Field>
                                            <ErrorMessage
                                              name="quantity"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                            {values.quantity > 0 ? (
                                              <Row className="mt-4 justify-content-center">
                                                <Col lg={4}>
                                                  <Card
                                                    outline
                                                    // color="primary"
                                                    style={{
                                                      backgroundColor:
                                                        this.props.upload
                                                          .color + "80",
                                                      color:
                                                        this.props.upload.color,
                                                    }}
                                                    className="border"
                                                  >
                                                    <CardBody className="p-4">
                                                      <div className="text-center">
                                                        <div className="avatar-md mx-auto mb-4">
                                                          <div
                                                            className="avatar-title rounded-circle text-light h1"
                                                            style={{
                                                              backgroundColor:
                                                                this.props
                                                                  .upload.color,
                                                            }}
                                                          >
                                                            <i className="mdi mdi-information-outline"></i>
                                                          </div>
                                                        </div>

                                                        <Row className="justify-content-center">
                                                          <Col xl={10}>
                                                            <h4
                                                              style={{
                                                                color:
                                                                  this.props
                                                                    .upload
                                                                    .color,
                                                              }}
                                                            >
                                                              {pricing(
                                                                values.pack,
                                                                values.quantity,
                                                                this.props
                                                                  .upload.prices
                                                              )}
                                                              €
                                                            </h4>
                                                            <p className="text-muted font-size-14 mb-4">
                                                              <Trans
                                                                i18nKey={
                                                                  "upload." +
                                                                  values.pack +
                                                                  "PackDescription"
                                                                }
                                                              ></Trans>
                                                            </p>
                                                          </Col>
                                                        </Row>
                                                      </div>
                                                    </CardBody>
                                                  </Card>
                                                </Col>
                                              </Row>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </TabPane>
                                      <TabPane tabId={4}>
                                        <div className="mb-3">
                                          <Label
                                            for="pin"
                                            className="form-label"
                                          >
                                            <Trans i18nKey="upload.pin" /> *
                                          </Label>
                                          <Field
                                            name="pin"
                                            label={i18n.t("upload.yourPin")}
                                            type="password"
                                            disabled={this.props.loading}
                                            className={
                                              "form-control" +
                                              (errors.pin && touched.pin
                                                ? " is-invalid"
                                                : "")
                                            }
                                            onChange={e => {
                                              const value =
                                                e.target.value || "";
                                              setFieldValue("pin", value);
                                            }}
                                            onBlur={handleBlur}
                                          />
                                          <ErrorMessage
                                            name="pin"
                                            component="div"
                                            className="invalid-feedback"
                                          />
                                        </div>
                                        {this.props.progress > 0 ? (
                                          <div className="mb-3">
                                            <Progress
                                              value={this.props.progress}
                                              // color="primary"
                                              style={{
                                                backgroundColor:
                                                  this.props.upload.color +
                                                  "40",
                                                color: this.props.upload.color,
                                                width:
                                                  this.props.progress + "%",
                                              }}
                                              animated
                                            ></Progress>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </TabPane>
                                    </TabContent>
                                  </div>
                                  <div className="actions clearfix">
                                    <ul>
                                      <li className="previous">
                                        <button
                                          className={
                                            "btn btn-primary " +
                                            (this.state.activeTab === 1
                                              ? "disabled"
                                              : "")
                                          }
                                          style={{
                                            backgroundColor:
                                              this.props.upload.color,
                                            borderColor: this.props.upload.color,
                                          }}
                                          disabled={
                                            this.props.loading ||
                                            this.state.processing
                                          }
                                          type="button"
                                          onClick={() => {
                                            this.toggleTab(
                                              this.state.activeTab - 1
                                            );
                                          }}
                                        >
                                          <Trans i18nKey="upload.previous"></Trans>
                                        </button>
                                      </li>

                                      <li className="next">
                                        <button
                                          className="btn btn-primary"
                                          type="submit"
                                          style={{
                                            backgroundColor:
                                              this.props.upload.color,
                                            borderColor:
                                              this.props.upload.color,
                                          }}
                                          disabled={
                                            this.props.loading ||
                                            this.state.processing
                                          }
                                        >
                                          {this.props.loading ? (
                                            <i className="fas fa-circle-notch fa-spin"></i>
                                          ) : this.state.activeTab ===
                                            schemaArray.length ? (
                                            <Trans i18nKey="upload.submit" />
                                          ) : (
                                            <Trans i18nKey="upload.next" />
                                          )}
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <Modal
                                  isOpen={this.state.modal_center}
                                  toggle={this.tog_center}
                                  centered={true}
                                >
                                  <ModalHeader>
                                    <Trans i18nKey="upload.areYouSureTitle"></Trans>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        this.setState({
                                          modal_center: false,
                                        })
                                      }
                                      className="close"
                                      data-dismiss="modal"
                                      aria-label="Close"
                                    >
                                      <span aria-hidden="true">&times;</span>
                                    </button>
                                  </ModalHeader>
                                  <ModalBody>
                                    <p>
                                      <Trans i18nKey="upload.areYouSureDescription"></Trans>
                                    </p>

                                    <ul>
                                      <li
                                        className={
                                          values.quantity >
                                          SAFEGUARD_MAX_QUANTITY
                                            ? "text-warning"
                                            : ""
                                        }
                                      >
                                        <Trans i18nKey="upload.quantity"></Trans>{" "}
                                        : {values.quantity}{" "}
                                        {values.quantity >
                                        SAFEGUARD_MAX_QUANTITY
                                          ? i18n.t("upload.maxWarning")
                                          : ""}
                                      </li>
                                      <li
                                        className={
                                          values.video === null
                                            ? "text-warning"
                                            : ""
                                        }
                                      >
                                        <Trans i18nKey="upload.video"></Trans> :{" "}
                                        {values.video === null
                                          ? i18n.t("upload.videoWarning")
                                          : i18n.t("upload.videoAttached")}
                                      </li>
                                    </ul>
                                  </ModalBody>
                                  <ModalFooter>
                                    <button
                                      className="btn btn-secondary"
                                      onClick={this.tog_center}
                                    >
                                      <Trans i18nKey="upload.cancel"></Trans>
                                    </button>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() => {
                                        this.tog_center();

                                        const legend =
                                          this.props.upload.defaultLegend
                                            .replace(
                                              "{date}",
                                              DateTime.now().toLocaleString(
                                                DateTime.DATE_SHORT
                                              )
                                            )
                                            .replace(
                                              "{place}",
                                              this.props.upload.place
                                            ) ||
                                          DateTime.now().toLocaleString(
                                            DateTime.DATE_SHORT
                                          ) +
                                            " - " +
                                            this.props.upload.place;
                                        const valuesToSend = {
                                          ...values,
                                          legend,
                                        };

                                        this.props.submitUpload(
                                          valuesToSend,
                                          this.props.history
                                        );
                                      }}
                                    >
                                      <Trans i18nKey="upload.confirm"></Trans>
                                    </button>
                                  </ModalFooter>
                                </Modal>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ) : (
                <Row className="justify-content-center">
                  <Col md={8} lg={6} xl={6}>
                    <Card className="overflow-hidden">
                      <div className="bg-primary bg-soft">
                        <Row>
                          <Col
                            className="col-12"
                            style={{ textAlign: "right" }}
                          >
                            <LanguageDropdown />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="col-7">
                            <div className="text-primary p-4">
                              <h5 className="text-primary">
                                <Trans i18nKey="upload.title" />
                              </h5>
                              <p className="card-text placeholder-glow">
                                <span className="placeholder col-7 mb-3"></span>
                              </p>
                            </div>
                          </Col>
                          <Col className="col-5 align-self-end">
                            <img
                              src={profileImg}
                              alt=""
                              className="img-fluid"
                            />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div className="auth-logo">
                          <Link
                            to={"/upload/" + this.props.match.params.idComputer}
                            className="auth-logo-dark"
                          >
                            <div className="avatar-md profile-user-wid mb-4">
                              <span className="avatar-title rounded-circle bg-primary">
                                <img src={logoImg} alt="" height="34" />
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          <form className="form-horizontal">
                            <div className="wizard clearfix">
                              <div className="steps clearfix">
                                <ul>
                                  <NavItem
                                    className={classnames({
                                      current: this.state.activeTab === 1,
                                      hidden: this.state.activeTab !== 1,
                                    })}
                                  >
                                    <NavLink
                                      disabled={true}
                                      className={
                                        classnames({
                                          active: true,
                                        }) + " text-primary"
                                      }
                                    >
                                      <span className="number">1/4</span>
                                      <Trans i18nKey="upload.step1"></Trans> :{" "}
                                      <Trans i18nKey="upload.photo"></Trans>
                                    </NavLink>
                                  </NavItem>
                                  {
                                    //   <NavItem>
                                    //   <NavLink disabled={true}>
                                    //     <span className="number">2.</span>
                                    //     <Trans i18nKey="upload.photo"></Trans>
                                    //   </NavLink>
                                    // </NavItem>
                                    // <NavItem>
                                    //   <NavLink disabled={true}>
                                    //     <span className="number">3.</span>
                                    //     <Trans i18nKey="upload.video"></Trans>
                                    //   </NavLink>
                                    // </NavItem>
                                    // <NavItem>
                                    //   <NavLink disabled={true}>
                                    //     <span className="number">4.</span>
                                    //     <Trans i18nKey="upload.pack"></Trans>
                                    //   </NavLink>
                                    // </NavItem>
                                    // <NavItem>
                                    //   <NavLink disabled={true}>
                                    //     <span className="number">5.</span>
                                    //     <Trans i18nKey="upload.validate"></Trans>
                                    //   </NavLink>
                                    // </NavItem>
                                  }
                                </ul>
                              </div>
                              <div className="content clearfix">
                                <TabContent
                                  activeTab={this.state.activeTab}
                                  className="body"
                                >
                                  <div className="mb-3 placeholder-glow">
                                    <Label className="form-label placeholder col-6"></Label>
                                    <Input
                                      type="text"
                                      className="placeholder col-6"
                                    />
                                  </div>
                                </TabContent>
                              </div>
                              <div className="actions clearfix">
                                <ul>
                                  <li className="previous">
                                    <button
                                      className={"btn btn-primary disabled "}
                                      type="button"
                                    >
                                      <Trans i18nKey="upload.previous" />
                                    </button>
                                  </li>

                                  <li className="next">
                                    <button
                                      className={"btn btn-primary disabled"}
                                      type="button"
                                    >
                                      <Trans i18nKey="upload.next" />
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </form>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Container>
            <div className="mt-5 text-center">
              <p>
                <Trans i18nKey="upload.alreadyAnAccount" />{" "}
                <Link
                  to={
                    this.props.loading || this.state.processing
                      ? "#"
                      : "../login"
                  }
                  className="fw-medium text-secondary"
                >
                  {" "}
                  <Trans i18nKey="upload.login" />
                </Link>{" "}
              </p>
              <p>
                © {new Date().getFullYear()} Teampix.{" "}
                <Trans i18nKey="login.craftedWith" />{" "}
                <i className="mdi mdi-heart text-danger" />{" "}
                <Trans i18nKey="login.by" /> Teampix
              </p>
            </div>
          </div>
        </div>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Upload.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  upload: PropTypes.object,
  done: PropTypes.bool,
  progress: PropTypes.number,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  getUpload: PropTypes.func,
  connectUpload: PropTypes.func,
  submitUpload: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, upload, done, progress } = state.Upload;
  return { error, loading, upload, done, progress };
};

export default connect(mapStateToProps, {
  getUpload,
  connectUpload,
  submitUpload,
})(withRouter(withTranslation()(Upload)));
