import { takeEvery, fork, put, call, all } from "redux-saga/effects";

// Login Redux States
import { GET_HELP, SEND_HELP } from "./actionTypes";
import {
  getHelpSuccess,
  getHelpFail,
  sendHelpSuccess,
  sendHelpFail,
} from "./actions";

//Include Both Helper File with needed methods
import { getHelpCall, sendHelpCall } from "../../helpers/backend";

function* getHelp({ payload: { help, history } }) {
  try {
    const response = yield call(getHelpCall, help);

    if (response.status === "success") {
      yield put(getHelpSuccess(response));
    } else {
      yield put(getHelpFail(response));
    }
  } catch (error) {
    yield put(getHelpFail(error));
    if (error === "backend.invalidCredentials") history.push("/logout");
  }
}

function* sendHelp({ payload: { help, history } }) {
  try {
    const response = yield call(sendHelpCall, help);
    if (response.status === "success") {
      yield put(sendHelpSuccess(response));
    } else {
      yield put(sendHelpFail(response));
    }
  } catch (error) {
    yield put(sendHelpFail(error));
    if (error === "backend.invalidCredentials") history.push("/logout");
  }
}
export function* watchHelp() {
  yield takeEvery(GET_HELP, getHelp);
  yield takeEvery(SEND_HELP, sendHelp);
}

function* HelpSaga() {
  yield all([fork(watchHelp)]);
}

export default HelpSaga;
