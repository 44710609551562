import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { map } from "lodash";

import { connect } from "react-redux";

//i18n
import i18n from "../../../i18n/i18n";
import { withTranslation } from "react-i18next";
import languages from "../../../i18n/languages";
import { editLanguage } from "../../../store/actions";

class LanguageDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      selectedLang: "en",
    };
  }

  componentDidMount() {
    const currentLanguage = localStorage.getItem("i18nextLng");
    this.setState({ selectedLang: currentLanguage });
  }

  changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    this.setState({ selectedLang: lang });
    this.props.editLanguage({"i18n" : lang}, this.props.history)
  };

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  };

  render() {
    const { selectedLang, menu } = this.state;

    return (
      <React.Fragment>
        <Dropdown isOpen={menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item" tag="button">
            <img
              src={languages[selectedLang].flag}
              alt="Teampix"
              height="16"
              className="me-1"
            />
          </DropdownToggle>
          <DropdownMenu className="language-switch dropdown-menu-end">
            {map(Object.keys(languages), key => (
              <DropdownItem
                key={key}
                onClick={() => this.changeLanguageAction(key)}
                className={`notify-item ${
                  selectedLang === key ? "active" : "none"
                }`}
              >
                <img
                  src={languages[key].flag}
                  alt="Teampix"
                  className="me-1"
                  height="12"
                />

                <span className="align-middle">{languages[key].label}</span>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

LanguageDropdown.propTypes = {
  editLanguage: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  user: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, success, user, loading } = state.Profile;
  return { error, success, user, loading };
};



export default connect(mapStateToProps, {editLanguage })(withTranslation()(LanguageDropdown));
