// URL local : http://www.localhost:3000/

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "reactstrap"; // Carousel, , CarouselItem

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//store
import { getOrder } from "../../../store/actions";

//i18n
import { withTranslation } from "react-i18next";

import imgLogoTeampix from "../../../assets/images/logo/logo-showcase.png";

// Carousel images
import img1 from "../../../assets/images/showcase/photo1.jpg";
import img2 from "../../../assets/images/showcase/photo2.jpg";

const items = [
  {
    src: img1,
    altText: "",
    caption: "",
  },
  {
    src: img2,
    altText: "",
    caption: "",
  },
];

class ShowCase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    // const slides = items.map(item => {
    //   return (
    //     <CarouselItem
    //       onExiting={this.onExiting}
    //       onExited={this.onExited}
    //       key={item.src}
    //     >
    //       <img
    //         src={item.src}
    //         className="d-block img-fluid"
    //         alt={item.altText}
    //       />
    //     </CarouselItem>
    //   );
    // });
    return (
      <React.Fragment>
        <div className="pb-5" style={{margin: "20px auto"}}>
          <Container>
            <Row className="pb-5 justify-content-center">
              <Col sm={6}>
                <img src={imgLogoTeampix} className="img-fluid"></img>
              </Col>
            </Row>
            <Row className="pt-5">
              <Col className="text-center">
                <h1
                  className="display-1 color-white"
                  style={{
                    fontFamily: "impact",
                    lineHeight: "76px",
                    textTransform: "uppercase",
                  }}
                >
                  Coming
                  <br />
                  Soon.
                  <br />
                </h1>
                <h6 className="display-6" style={{textTransform: "uppercase", fontFamily: "jura"}}>(Bientôt)</h6>
              </Col>
             {/* <Col
                sm={6}
                style={{
                  padding: "20px",
                  background:
                    "linear-gradient(to right, black 20px, transparent 20px) 0 0, linear-gradient(to right, black 20px, transparent 20px) 0 100%, linear-gradient(to left, black 20px, transparent 20px) 100% 0, linear-gradient(to left, black 20px, transparent 20px) 100% 100%, linear-gradient(to bottom, black 20px, transparent 20px) 0 0, linear-gradient(to bottom, black 20px, transparent 20px) 100% 0, linear-gradient(to top, black 20px, transparent 20px) 0 100%, linear-gradient(to top, black 20px, transparent 20px) 100% 100%",
                  backgroundRepeat: " no-repeat",
                  backgroundSize: "100px 100px",
                }}
              >
                <Carousel
                  activeIndex={this.state.activeIndex}
                  fade={true}
                  next={this.next}
                  previous={this.previous}
                >
                  {slides}
                </Carousel>
              </Col>*/
            }
             
            </Row>
          </Container>
        </div>
        <div className="bg-dark">
          <Container>
            <Row className="pt-5 text-center">
              <Col>
                <h3 className="text-white" style={{textTransform: "uppercase", fontFamily: "jura"}}>Under Construction... Contact us</h3><br/>
                <h4 className="text-white" style={{textTransform: "uppercase", fontFamily: "jura"}}>
                  <a
                    href="mailto:hello@teampix.eu"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white"
                  >
                    hello@teampix.eu
                  </a>
                </h4>
                {
                  /*<h4 style={{textTransform: "uppercase", fontFamily: "jura"}}>
                          <a href="tel:+322497826898" className="text-white">
                            +32 2 497 82 68 98
                          </a>
                  </h4>
                
                */}<br/><br/><br/> 
              </Col>
            </Row>
          </Container>
        </div>

        <footer
          className="footer"
          style={{ left: "0px", backgroundColor: "#f5f5f5" }}
        >
          <div className="container">
            <Row>
              <Col className="text-center">
                © {new Date().getFullYear()} Teampix. {
                  // Images de gpointstudio sur Freepik
                }
              </Col>
             {/* <Col sm={6}></Col> */}
            </Row>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

ShowCase.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  order: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  getOrder: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, order } = state.Order;
  return { error, loading, order };
};

export default connect(mapStateToProps, {
  getOrder,
})(withRouter(withTranslation()(ShowCase)));
