import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  Col,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//i18n
import i18n from "../../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      activeTemplate: "grid",
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.toggleTemplate = this.toggleTemplate.bind(this);
    this.attachment1Ref = React.createRef();
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggleTemplate(tab) {
    if (this.state.activeTemplate !== tab) {
      this.setState({
        activeTemplate: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <Col xl={12} lg={12}>
          <Card>
            <ul
              className="nav nav-tabs nav-tabs-custom justify-content-center pt-2"
              role="tablist"
            >
              <NavItem>
                <NavLink
                  to="#"
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggleTab("1");
                  }}
                >
                  <Trans i18nKey="help.faq" />
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggleTab("2");
                  }}
                >
                  <Trans i18nKey="help.contact" />
                </NavLink>
              </NavItem>
            </ul>
            <TabContent className="p-4" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div>
                  <Row className="justify-content-center">
                    <Col>
                      <div>
                        <Row className="align-items-center">
                          <div className="col-4">
                            <div>
                              <h5 className="mb-0">
                                <Trans i18nKey="help.faq" />
                              </h5>
                            </div>
                          </div>

                          <Col xs={8}>
                            <div>
                              <ul className="nav nav-pills justify-content-end">
                                <NavItem>
                                  <NavLink
                                    className="disabled"
                                    to="#"
                                    tabIndex="-1"
                                  >
                                    <Trans i18nKey="help.view" />
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <button
                                    className={
                                      "nav-link " +
                                      classnames({
                                        active:
                                          this.state.activeTemplate == "list",
                                      })
                                    }
                                    onClick={() => {
                                      this.toggleTemplate("list");
                                    }}
                                  >
                                    <i className="mdi mdi-format-list-bulleted"></i>
                                  </button>
                                </NavItem>
                                <NavItem>
                                  <button
                                    className={
                                      "nav-link " +
                                      classnames({
                                        active:
                                          this.state.activeTemplate == "grid",
                                      })
                                    }
                                    onClick={() => {
                                      this.toggleTemplate("grid");
                                    }}
                                  >
                                    <i className="mdi mdi-view-grid-outline"></i>
                                  </button>
                                </NavItem>
                              </ul>
                            </div>
                          </Col>
                        </Row>

                        <hr className="mb-4" />
                        {this.state.activeTemplate == "grid" ? (
                          <div>
                            {" "}
                            <Row>
                              {i18n
                                .getResourceBundle(
                                  localStorage.getItem("i18nextLng") || "fr",
                                  ""
                                )
                                .help.articles.map((val, key) => {
                                  return (
                                    <Col sm={6} key={"article" + key}>
                                      <Card className="p-1 border shadow-none">
                                        <div className="p-3">
                                          <h5>
                                            <Link
                                              to="/blog-details"
                                              className="text-dark"
                                            >
                                              {val.title}
                                            </Link>
                                          </h5>
                                        </div>

                                        {val.video ? (
                                          <div className="ratio ratio-16x9">
                                            <iframe
                                              title={val.title}
                                              allowFullScreen
                                              src={val.video}
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            ></iframe>
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        {val.image ? (
                                          <div className="position-relative">
                                            <img
                                              src={val.image}
                                              alt=""
                                              className="img-thumbnail"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}

                                        <div className="p-3">
                                          <ul className="list-inline">
                                            <li className="list-inline-item me-3">
                                              <Link
                                                to="#"
                                                className="text-muted"
                                              >
                                                <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                                {val.category}
                                              </Link>
                                            </li>
                                          </ul>
                                          <p>{val.description}</p>
                                        </div>
                                      </Card>
                                    </Col>
                                  );
                                })}
                            </Row>
                            <hr className="my-4" />
                          </div>
                        ) : (
                          <div>
                            {i18n
                              .getResourceBundle(
                                localStorage.getItem("i18nextLng") || "fr",
                                ""
                              )
                              .help.articles.map((val, key) => {
                                return (
                                  <div key={"article" + key}>
                                    <div>
                                      <h5>
                                        <Link
                                          to="blog-details"
                                          className="text-dark"
                                        >
                                          {val.title}
                                        </Link>
                                      </h5>
                                      {val.video ? (
                                        <div className="ratio ratio-16x9">
                                          <iframe
                                            title={val.title}
                                            allowFullScreen
                                            src={val.video}
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                          ></iframe>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {val.image ? (
                                        <div className="position-relative mb-3">
                                          <img
                                            src={val.image}
                                            alt=""
                                            className="img-thumbnail"
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <ul className="list-inline">
                                        <li className="list-inline-item me-3">
                                          <Link to="#" className="text-muted">
                                            <i className="bx bx-purchase-tag-alt align-middle text-muted me-1"></i>{" "}
                                            {val.category}
                                          </Link>
                                        </li>
                                      </ul>
                                      <p>{val.description}</p>
                                    </div>
                                    <hr className="my-5" />
                                  </div>
                                );
                              })}
                          </div>
                        )}

                        {
                          // <div className="text-center">
                          //   <ul className="pagination justify-content-center pagination-rounded">
                          //     <li className="page-item disabled">
                          //       <Link to="#" className="page-link">
                          //         <i className="mdi mdi-chevron-left"></i>
                          //       </Link>
                          //     </li>
                          //     <li className="page-item">
                          //       <Link to="#" className="page-link">
                          //         1
                          //       </Link>
                          //     </li>
                          //     <li className="page-item active">
                          //       <Link to="#" className="page-link">
                          //         2
                          //       </Link>
                          //     </li>
                          //     <li className="page-item">
                          //       <Link to="#" className="page-link">
                          //         3
                          //       </Link>
                          //     </li>
                          //     <li className="page-item">
                          //       <Link to="#" className="page-link">
                          //         ...
                          //       </Link>
                          //     </li>
                          //     <li className="page-item">
                          //       <Link to="#" className="page-link">
                          //         10
                          //       </Link>
                          //     </li>
                          //     <li className="page-item">
                          //       <Link to="#" className="page-link">
                          //         <i className="mdi mdi-chevron-right"></i>
                          //       </Link>
                          //     </li>
                          //   </ul>
                          // </div>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>

              <TabPane tabId="2">
                <div>
                  <Row className="justify-content-center">
                    <Col>
                      <h5>
                        <Trans i18nKey="help.contact" />
                      </h5>
                      <div className="">
                        <Formik
                          enableReinitialize={true}
                          initialValues={{
                            firstname:
                              (this.props.help && this.props.help.firstname) ||
                              "",
                            lastname:
                              (this.props.help && this.props.help.lastname) ||
                              "",
                            email:
                              (this.props.help && this.props.help.email) || "",
                            phone:
                              (this.props.help && this.props.help.phone) || "",
                            country:
                              (this.props.help && this.props.help.country) ||
                              i18n.t("profile.defaultCountry") ||
                              "FR",
                            i18n:
                              (this.props.help && this.props.help.i18n) ||
                              localStorage.getItem("i18nextLng") ||
                              "fr",
                            idComputer:
                              (this.props.help && this.props.help.idComputer) ||
                              0,
                            idPlace:
                              (this.props.help && this.props.help.idPlace) || 0,
                            message: "",
                            attachment1: null,
                          }}
                          validationSchema={Yup.object().shape({
                            firstname: Yup.string().required(
                              i18n.t("profile.enterFirstname")
                            ),
                            lastname: Yup.string().required(
                              i18n.t("profile.enterLastname")
                            ),
                            email: Yup.string()
                              .email(i18n.t("profile.enterValidEmail"))
                              .required(i18n.t("profile.enterEmail")),
                            phone: Yup.string()
                              .required(i18n.t("profile.enterPhone"))
                              .test({
                                name: "phone",
                                message: i18n.t("profile.enterValidPhone"),
                                test: function (value) {
                                  if (value === undefined) return false;
                                  return isValidPhoneNumber(value);
                                },
                              }),
                            message: Yup.string().required(
                              i18n.t("help.enterMessage")
                            ),
                          })}
                          onSubmit={values => {
                            this.props.sendHelp(values, this.props.history);
                          }}
                        >
                          {({
                            handleBlur,
                            setFieldValue,
                            values,
                            errors,
                            touched,
                          }) => (
                            <Form className="form-horizontal">
                              {this.props.help ? (
                                <div className="text-center">
                                  <div className="mb-1">
                                    <i className="bx bx bx-printer display-6 text-primary" />
                                  </div>
                                  <h6>
                                    <Trans i18nKey="place.printStation"></Trans>
                                  </h6>
                                  <p>
                                    <Trans i18nKey="place.id"></Trans> :{" "}
                                    {this.props.help.idComputer}
                                    <br />
                                    <Trans i18nKey="help.place"></Trans> :{" "}
                                    {this.props.help.name}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                              <Row>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label
                                      for="firstname"
                                      className="form-label"
                                    >
                                      <Trans i18nKey="profile.firstname" />*
                                    </Label>
                                    <Field
                                      name="firstname"
                                      type="text"
                                      disabled={this.props.loading}
                                      className={
                                        "form-control" +
                                        (errors.firstname && touched.firstname
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="firstname"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                                <Col lg="6">
                                  <div className="mb-3">
                                    <Label
                                      for="lastname"
                                      className="form-label"
                                    >
                                      <Trans i18nKey="profile.lastname" />*
                                    </Label>
                                    <Field
                                      name="lastname"
                                      type="text"
                                      disabled={this.props.loading}
                                      className={
                                        "form-control" +
                                        (errors.lastname && touched.lastname
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="lastname"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <div className="mb-3">
                                <Label for="email" className="form-label">
                                  <Trans i18nKey="profile.email" />*
                                </Label>
                                <Field
                                  name="email"
                                  type="email"
                                  disabled={this.props.loading}
                                  className={
                                    "form-control" +
                                    (errors.email && touched.email
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={e => {
                                    const value = e.target.value || "";
                                    setFieldValue("email", value.toLowerCase());
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <div className="mb-3">
                                <Label for="phone" className="form-label">
                                  <Trans i18nKey="profile.phone" />*
                                </Label>

                                <PhoneInput
                                  name="phone"
                                  type="tel"
                                  disabled={this.props.loading}
                                  className={
                                    "form-control" +
                                    (errors.phone && touched.phone
                                      ? " is-invalid"
                                      : "")
                                  }
                                  defaultCountry={i18n.t(
                                    "profile.defaultCountry"
                                  )}
                                  value={this.props.help && this.props.help.phone || ""}
                                  onChange={phone => {
                                    setFieldValue("phone", phone);
                                  }}
                                  onCountryChange={countryCode => {
                                    setFieldValue("country", countryCode);
                                  }}
                                  countries={["FR", "BE"]}
                                />

                                <ErrorMessage
                                  name="phone"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="message" className="form-label">
                                  <Trans i18nKey="help.message" /> *
                                </Label>
                                <Field
                                  name="message"
                                  placeholder={i18n.t("help.yourMessage")}
                                  as="textarea"
                                  rows="3"
                                  disabled={this.props.loading}
                                  className={
                                    "form-control" +
                                    (errors.message && touched.message
                                      ? " is-invalid"
                                      : "")
                                  }
                                  onChange={e => {
                                    const value = e.target.value || "";
                                    setFieldValue("message", value);
                                  }}
                                  onBlur={handleBlur}
                                />
                                <ErrorMessage
                                  name="message"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                              <div className="mb-3">
                                <Label for="attachment1" className="form-label">
                                  <Trans i18nKey="place.attachment1" />
                                </Label>
                                <div className="input-group">
                                  <input
                                    ref={this.attachment1Ref}
                                    name="attachment1"
                                    type="file"
                                    disabled={this.props.loading}
                                    // accept={PHOTO_FILE_TYPE.join(",")}
                                    className={
                                      "form-control" +
                                      (errors.attachment1 && touched.attachment1
                                        ? " is-invalid"
                                        : "")
                                    }
                                    onChange={e => {
                                      const value = e.target.files[0] || null;
                                      setFieldValue("attachment1", value);
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {values.attachment1 !== null ? (
                                    <Button
                                      color="danger"
                                      type="button"
                                      onClick={() => {
                                        this.attachment1Ref.current.value =
                                          null;
                                        setFieldValue("attachment1", null);
                                      }}
                                      disabled={this.props.loading}
                                    >
                                      <i className="fas fa-times"></i>
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <ErrorMessage
                                  name="attachment1"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>

                              <Alert color={"info"}>
                                <i className="fas fa-info-circle"></i>{" "}
                                <Trans i18nKey="help.disclaimer"></Trans>
                              </Alert>
                              <div className="text-center">
                                <button
                                  className="btn btn-primary"
                                  type="submit"
                                  disabled={this.props.loading}
                                >
                                  <Trans i18nKey="help.send"></Trans>
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </Col>
                  </Row>
                </div>
              </TabPane>
            </TabContent>
          </Card>
        </Col>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

FAQ.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  help: PropTypes.object,
  sendHelp: PropTypes.func,
};

export default withRouter(withTranslation()(FAQ));
