import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Card,
  CardImg,
  CardBody,
  Col,
  Container,
  Row,
  Label,
} from "reactstrap";

import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Helmet, HelmetProvider } from "react-helmet-async";

// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import noavatar from "../../assets/images/user/user.png";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

// actions
import { getProfile, editProfile } from "../../store/actions";

import img1 from "../../assets/images/card/account.png";
import timezones from "../../assets/json/timezones.json";

class Profile extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getProfile(this.props.history);
  }

  render() {
    return (
      <React.Fragment>
      <HelmetProvider>
      <Helmet>
        <title>{i18n.t("profile.title") + " | Teampix"}</title>
      </Helmet>
    </HelmetProvider>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb
              title="Tribery"
              breadcrumbItem={i18n.t("profile.title")}
            />
            <Row className="justify-content-md-center">
              <Col lg="6">
                <h4 className="card-title mb-4">
                  <Trans i18nKey="profile.summary"></Trans>
                </h4>

                <Card>
                  <CardImg
                    top
                    className="img-fluid bg-primary bg-soft"
                    src={img1}
                    alt="profile"
                  />
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          referrerPolicy="no-referrer"
                          alt="avatar"
                          src={
                            this.props.user.avatar
                              ? this.props.user.avatar
                              : noavatar
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = noavatar;
                          }}
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>
                            {this.props.user.firstname}{" "}
                            {this.props.user.lastname}
                          </h5>
                          <p className="text-muted">{this.props.user.email}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col lg="6">
                <h4 className="card-title mb-4">
                  <Trans i18nKey="profile.information"></Trans>
                </h4>

                <Card>
                  <CardBody>
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: (this.props.user && this.props.user.email) || "",
                        firstname:
                          (this.props.user && this.props.user.firstname) || "",
                        lastname:
                          (this.props.user && this.props.user.lastname) || "",
                        phone: (this.props.user && this.props.user.phone) || "",
                        timezone:
                          (this.props.user && this.props.user.timezone) ||
                          "Europe/Paris",
                        country:
                          (this.props.user && this.props.user.country) ||
                          i18n.t("profile.defaultCountry") ||
                          "",
                      }}
                      validationSchema={Yup.object().shape({
                        firstname: Yup.string().required(
                          i18n.t("profile.enterFirstname")
                        ),
                        lastname: Yup.string().required(
                          i18n.t("profile.enterLastname")
                        ),
                        email: Yup.string()
                          .email(i18n.t("profile.enterValidEmail"))
                          .required(i18n.t("profile.enterEmail")),
                        phone: Yup.string()
                          .required(i18n.t("profile.enterPhone"))
                          .test({
                            name: "phone",
                            message: i18n.t("profile.enterValidPhone"),
                            test: function (value) {
                              if (value === undefined) return false;
                              return isValidPhoneNumber(value);
                            },
                          }),
                        timezone: Yup.string().required(
                          i18n.t("profile.enterTimezone")
                        ),
                      })}
                      onSubmit={values => {
                        this.props.editProfile(values, this.props.history);
                      }}
                    >
                      {({ handleBlur, setFieldValue, errors, touched }) => (
                        <Form className="form-horizontal">
                          <Row>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="firstname" className="form-label">
                                  <Trans i18nKey="profile.firstname" />*
                                </Label>
                                <Field
                                  name="firstname"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.firstname && touched.firstname
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="firstname"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Col>
                            <Col lg="6">
                              <div className="mb-3">
                                <Label for="lastname" className="form-label">
                                  <Trans i18nKey="profile.lastname" />*
                                </Label>
                                <Field
                                  name="lastname"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.lastname && touched.lastname
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="lastname"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-3">
                            <Label for="email" className="form-label">
                              <Trans i18nKey="profile.email" />*
                            </Label>
                            <Field
                              name="email"
                              type="email"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                              onChange={e => {
                                const value = e.target.value || "";
                                setFieldValue("email", value.toLowerCase());
                              }}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mb-3">
                            <Label for="phone" className="form-label">
                              <Trans i18nKey="profile.phone" />*
                            </Label>

                            <PhoneInput
                              name="phone"
                              type="tel"
                              className={
                                "form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                              defaultCountry={i18n.t("profile.defaultCountry")}
                              value={this.props.user.phone}
                              onChange={(phone) => {
                                setFieldValue("phone", phone);
                              }}
                              onCountryChange={countryCode => {
                                setFieldValue("country", countryCode);
                              }}
                              countries={["FR", "BE"]}
                            />

                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mb-3">
                            <Label for="timezone" className="form-label">
                              <Trans i18nKey="profile.timezone" />*
                            </Label>
                            <Field
                              name="timezone"
                              as="select"
                              className={
                                "form-control" +
                                (errors.timezone && touched.timezone
                                  ? " is-invalid"
                                  : "")
                              }
                            >
                              <option value="" disabled={true}>
                                {i18n.t("profile.chooseTimezone")}
                              </option>
                              {timezones.map((hour, key) => (
                                <option value={hour.value} key={"hour-" + key}>
                                  {hour.text}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage
                              name="timezone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="text-center mt-4">
                            <Button
                              type="submit"
                              color="primary"
                              disabled={this.props.loading}
                            >
                              <Trans i18nKey="profile.update"></Trans>
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  getProfile: PropTypes.func,
  user: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, success, user, loading } = state.Profile;
  return { error, success, user, loading };
};

export default withRouter(
  connect(mapStateToProps, { getProfile, editProfile })(
    withTranslation()(Profile)
  )
);
