import React, { Component } from "react";
import { BrowserRouter as Router, Switch, withRouter } from "react-router-dom";

// Import Routes
import {
  authInternalProtectedRoutes,
  authProtectedRoutes,
  publicRoutes,
  publicHelpRoutes,
  showCaseRoutes,
} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

class App extends Component {
  constructor(props) {
    super(props);
  }

  getSubDomain() {
    const host = window.location.host;
    const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
    if (arr.length > 0) return arr[0];
    return host.includes("localhost") ? "web" : "";
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {(this.getSubDomain() == "" || this.getSubDomain() == "www") &&
              showCaseRoutes.map((route, idx) => {
                return (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={0}
                    subDomain=""
                    exact
                  />
                );
              })}
            {this.getSubDomain() == "help" &&
              publicHelpRoutes.map((route, idx) => {
                return (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={0}
                    subDomain="help"
                    exact
                  />
                );
              })}
            {this.getSubDomain() == "web" &&
              publicRoutes.map((route, idx) => {
                return (
                  <AppRoute
                    path={route.path}
                    layout={NonAuthLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={0}
                    subDomain="web"
                  />
                );
              })}

            {this.getSubDomain() == "web" &&
              authInternalProtectedRoutes.map((route, idx) => {
                return (
                  <AppRoute
                    path={route.path}
                    layout={VerticalLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={2}
                    subDomain="web"
                    exact
                  />
                );
              })}

            {this.getSubDomain() == "web" &&
              authProtectedRoutes.map((route, idx) => {
                return (
                  <AppRoute
                    path={route.path}
                    layout={HorizontalLayout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={1}
                    subDomain="web"
                    exact
                  />
                );
              })}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
