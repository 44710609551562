import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  SEND_OTP_USER,
  SEND_OTP_USER_SUCCESS,
  SEND_OTP_USER_FAIL,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAIL,
  API_ERROR,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../../i18n/i18n";

export const loginUser = (user, redirect, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, redirect, history },
  };
};

export const loginUserSuccess = user => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
};

export const loginUserFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: LOGIN_USER_FAIL,
    payload: fail,
  };
};

export const sendOTP = otp => {
  return {
    type: SEND_OTP_USER,
    payload: otp,
  };
};

export const sendOTPSuccess = password => {
  toast.success(i18n.t(password.message));
  return {
    type: SEND_OTP_USER_SUCCESS,
    payload: password,
  };
};

export const sendOTPFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: SEND_OTP_USER_FAIL,
    payload: fail,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = history => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: history,
  };
};
export const logoutUserFail = fail => {
  return {
    type: LOGOUT_USER_FAIL,
    payload: fail,
  };
};

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
