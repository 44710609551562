import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Helmet, HelmetProvider } from "react-helmet-async";

// notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//store
import { getPlace, editHardware } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

class Place extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOpenedHardware: {},
      modalPinIsOpened: false,
    };
    this.togModalPin = this.togModalPin.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.idPlace > 0) {
      const postData = {
        idPlace: this.props.match.params.idPlace,
        type: "hardware",
      };
      this.props.getPlace(postData, this.props.history);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.editHardwareUpdated !== this.props.editHardwareUpdated &&
      this.props.editHardwareUpdated === true
    ) {
      this.setState({ modalPinIsOpened: false });
      const postData = {
        idPlace: this.props.match.params.idPlace,
        type: "hardware",
      };
      this.props.getPlace(postData, this.props.history);
    }
  }

  togModalPin() {
    this.setState(prevState => ({
      modalPinIsOpened: !prevState.modalPinIsOpened,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (this.props.place && this.props.place.hardware
                    ? " : " + this.props.place.hardware[0].name
                    : "") +
                  " | Teampix"}
              </title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {this.props.place && this.props.place.hardware ? (
              <div>
                <Modal
                  isOpen={this.state.modalPinIsOpened}
                  toggle={this.togModalPin}
                  centered={true}
                  backdrop="static"
                >
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      pin: this.state.currentOpenedHardware.pin || "0000",
                      idComputer:
                        this.state.currentOpenedHardware.idComputer || 0,
                    }}
                    validationSchema={Yup.object().shape({
                      pin: Yup.string()
                        .required(i18n.t("place.enterPin"))
                        .matches("^[0-9]{4}$", i18n.t("place.enter4digits")),
                    })}
                    onSubmit={values => {
                      this.props.editHardware(values, this.props.history);
                    }}
                  >
                    {({ handleBlur, setFieldValue, errors, touched }) => (
                      <Form className="form-horizontal">
                        <ModalHeader>
                          <span className="text-warning">
                            <Trans i18nKey="place.pinTitle"></Trans>
                          </span>
                          <button
                            type="button"
                            onClick={() =>
                              this.setState({
                                modalPinIsOpened: false,
                              })
                            }
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            disabled={this.props.loading}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </ModalHeader>
                        <ModalBody>
                          <div className="mt-3 mb-3">
                            <Trans i18nKey="place.pinDescription"></Trans>
                          </div>
                          <div className="text-center">
                            <div className="mb-1">
                              <i
                                className="bx bx bx-printer display-6"
                                style={{
                                  lineHeight: "1",
                                  color: this.state.currentOpenedHardware.dashboardColor,
                                }}
                              />
                            </div>
                            <h6>
                              <Trans i18nKey="place.printStation"></Trans>
                            </h6>
                            <p>
                              <Trans i18nKey="place.id"></Trans> :{" "}
                              {this.state.currentOpenedHardware.idComputer}
                              <br />
                              <Trans i18nKey="place.orders"></Trans> :{" "}
                              {this.state.currentOpenedHardware.orders}
                            </p>
                          </div>
                          <div className="mb-3">
                            <Label for="pin" className="form-label">
                              <Trans i18nKey={"place.yourPin"} /> *
                            </Label>
                            <Field
                              name="pin"
                              label={i18n.t("place.yourPin")}
                              type="string"
                              disabled={this.props.loading}
                              className={
                                "form-control" +
                                (errors.pin && touched.pin ? " is-invalid" : "")
                              }
                              onChange={e => {
                                const value = e.target.value || "";
                                setFieldValue("pin", value);
                              }}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="pin"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Alert color={"danger"}>
                            <i className="fas fa-info-circle"></i>{" "}
                            <Trans i18nKey="place.pinDisclaimer"></Trans>
                          </Alert>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.togModalPin}
                            disabled={this.props.loading}
                          >
                            <Trans i18nKey="place.cancel"></Trans>
                          </button>
                          <button
                            className="btn btn-warning"
                            type="submit"
                            disabled={this.props.loading}
                          >
                            <Trans i18nKey="place.update"></Trans>
                          </button>
                        </ModalFooter>
                      </Form>
                    )}
                  </Formik>
                </Modal>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Card className="overflow-hidden">
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <h5 className="mt-3 mb-3">
                            <Trans i18nKey="place.hardware" />
                          </h5>
                          <Row>
                            {this.props.place.hardware.length > 0 &&
                            this.props.place.hardware[0].idComputer !== null ? (
                              this.props.place.hardware.map(prop => {
                                return (
                                  <Col
                                    key={prop.idComputer}
                                    xl={4}
                                    l={6}
                                    className="mb-5"
                                  >
                                    <div className="text-center">
                                      <div className="mb-1">
                                        <i
                                          className="bx bx bx-printer display-6"
                                          style={{
                                            lineHeight: "1",
                                            color: prop.dashboardColor,
                                          }}
                                        />
                                      </div>
                                      <h6>
                                        <Trans i18nKey="place.printStation"></Trans>
                                      </h6>
                                      <p>
                                        <Trans i18nKey="place.id"></Trans> :{" "}
                                        {prop.idComputer}
                                        <br />
                                        <Trans i18nKey="place.orders"></Trans> :{" "}
                                        {prop.orders}
                                        <br />
                                        <Link
                                          to={"/upload/" + prop.idComputer}
                                          target="_blank"
                                          className="btn btn-primary position-relative p-0 avatar-xs rounded-circle"
                                          style={{
                                            backgroundColor: prop.dashboardColor,
                                            borderColor: prop.dashboardColor,
                                          }}
                                          title={i18n.t("place.uploader")}
                                        >
                                          <span className="avatar-title bg-transparent text-reset">
                                            <i className="bx bx-cloud-upload"></i>
                                          </span>
                                        </Link>{" "}
                                        <button
                                          target="_blank"
                                          className="btn btn-warning position-relative p-0 avatar-xs rounded-circle"
                                          title={i18n.t("place.pin")}
                                          onClick={e => {
                                            this.setState({
                                              currentOpenedHardware: prop,
                                            });
                                            this.togModalPin(e);
                                          }}
                                        >
                                          <span className="avatar-title bg-transparent text-reset">
                                            <i className="bx bx-key"></i>
                                          </span>
                                        </button>
                                      </p>
                                    </div>
                                  </Col>
                                );
                              })
                            ) : (
                              <p>
                                <Trans i18nKey="hardware.noComputers"></Trans>
                              </p>
                            )}
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Place.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  editHardwareUpdated : PropTypes.bool,
  getPlace: PropTypes.func,
  editHardware: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, place, editHardwareUpdated } = state.Place;
  return { error, loading, place, editHardwareUpdated };
};

export default connect(mapStateToProps, {
  getPlace,
  editHardware,
})(withRouter(withTranslation()(Place)));
