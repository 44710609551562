import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Alert } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";

// notification
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//store
import { getPlace } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

class Place extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.match.params.idPlace > 0) {
      const postData = {
        idPlace: this.props.match.params.idPlace,
        type: "info",
      };
      this.props.getPlace(postData, this.props.history);
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>
                {i18n.t("place.title") +
                  (this.props.place && this.props.place.info
                    ? " : " + this.props.place.info.name
                    : "") +
                  " | Teampix"}
              </title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {this.props.place && this.props.place.info ? (
              <div>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Row>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  <Trans i18nKey="place.orders"></Trans>
                                </p>
                                <h4 className="mb-0">
                                  {this.props.place.info.orders}
                                </h4>
                              </div>
                              <div
                                className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                                style={{
                                  backgroundColor: this.props.place.info.dashboardColor,
                                }}
                              >
                                <span className="avatar-title">
                                  <i
                                    className={"bx bx-copy-alt font-size-24"}
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  <Trans i18nKey="place.revenue"></Trans>
                                </p>
                                <h4 className="mb-0">
                                  {this.props.place.info.revenue} €
                                </h4>
                              </div>
                              <div
                                className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                                style={{
                                  backgroundColor: this.props.place.info.dashboardColor,
                                }}
                              >
                                <span className="avatar-title">
                                  <i
                                    className={"bx bx-archive-in font-size-24"}
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="4">
                        <Card className="mini-stats-wid">
                          <CardBody>
                            <div className="d-flex">
                              <div className="flex-grow-1">
                                <p className="text-muted fw-medium">
                                  <Trans i18nKey="place.averagePrice"></Trans>
                                </p>
                                <h4 className="mb-0">
                                  {this.props.place.info.averagePrice} €
                                </h4>
                              </div>
                              <div
                                className="mini-stat-icon avatar-sm rounded-circle align-self-center"
                                style={{
                                  backgroundColor: this.props.place.info.dashboardColor,
                                }}
                              >
                                <span className="avatar-title">
                                  <i
                                    className={
                                      "bx bx-purchase-tag-alt font-size-24"
                                    }
                                  />
                                </span>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Card>
                          <CardBody>
                            <h4 className="mb-4 card-title">
                              <Trans i18nKey="place.monthlyRevenue"></Trans>
                            </h4>
                            <p className="text-muted">
                              <Trans i18nKey="place.thisMonth"></Trans>
                            </p>
                            <h3>{this.props.place.info.montlyRevenue} €</h3>
                            <p className="text-muted">
                              {this.props.place.info.previousMonthlyRevenue >
                              0 ? (
                                this.props.place.info.montlyRevenue >
                                this.props.place.info.previousMonthlyRevenue ? (
                                  <span className="text-success me-2">
                                    {" " +
                                      parseInt(
                                        ((this.props.place.info.montlyRevenue -
                                          this.props.place.info
                                            .previousMonthlyRevenue) *
                                          100) /
                                          this.props.place.info
                                            .previousMonthlyRevenue
                                      )}
                                    % <i className="mdi mdi-arrow-up"></i>
                                  </span>
                                ) : (
                                  <span className="text-danger me-2">
                                    {" " +
                                      parseInt(
                                        ((this.props.place.info.montlyRevenue -
                                          this.props.place.info
                                            .previousMonthlyRevenue) *
                                          100) /
                                          this.props.place.info
                                            .previousMonthlyRevenue
                                      )}
                                    % <i className="mdi mdi-arrow-down"></i>
                                  </span>
                                )
                              ) : (
                                <span className="text-muted me-2">
                                  {" 0"}%{" "}
                                  <i className="mdi mdi-window-minimize"></i>
                                </span>
                              )}{" "}
                              <Trans i18nKey="place.FromPreviousPeriod"></Trans>
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card>
                          <CardBody>
                            <h4 className="mb-4 card-title">
                              <Trans i18nKey="place.monthlyInvoice"></Trans>
                            </h4>
                            <p className="text-muted">
                              <Trans i18nKey="place.thisMonth"></Trans>
                            </p>
                            <h3>{this.props.place.info.montlyInvoice} €</h3>
                            {/*<div className="mt-3">
                              <Link
                                to={"/place/" + this.props.place.info.idPlace + "/invoicing"}
                                className="btn btn-primary waves-effect waves-light btn-sm"
                                style={{
                                  borderColor: this.props.place.info.dashboardColor,
                                  backgroundColor: this.props.place.info.dashboardColor,
                                }}
                              >
                              <Trans i18nKey="place.needMoreInfoAboutInvoicing"></Trans>{" "}
                                <i className="mdi mdi-arrow-right ms-1"></i>
                              </Link>
                              </div>*/}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>

                    <Card>
                      <CardBody>
                        <div>
                          <h5 className="mb-3">
                            <Trans i18nKey="place.stock" />
                          </h5>
                          <Alert
                            color={
                              this.props.place.info.paperStockLeft <= 0
                                ? "danger"
                                : this.props.place.info.paperStockLeft <= 10
                                ? "warning"
                                : "success"
                            }
                          >
                            <i className="far fa-copy"></i>{" "}
                            <Trans i18nKey="place.paper" /> :{" "}
                            {this.props.place.info.paperStockLeft}
                          </Alert>
                          {this.props.place.info.sleeveOption > 0 ? (
                            <Alert
                              color={
                                this.props.place.info.sleeveStockLeft <= 0
                                  ? "danger"
                                  : this.props.place.info.sleeveStockLeft <= 10
                                  ? "warning"
                                  : "success"
                              }
                            >
                              <i className="far fa-envelope"></i>{" "}
                              <Trans i18nKey="place.sleeve" /> :{" "}
                              {this.props.place.info.sleeveStockLeft}
                            </Alert>
                          ) : (
                            <Alert color="disabled">
                              <i className="far fa-envelope"></i>{" "}
                              <Trans i18nKey="place.sleeveOptionDisabled"></Trans>
                            </Alert>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Place.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  place: PropTypes.object,
  getPlace: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, place } = state.Place;
  return { error, loading, place };
};

export default connect(mapStateToProps, {
  getPlace,
})(withRouter(withTranslation()(Place)));
