import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Container, Row, Label } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

//Social Media Imports
// import { GoogleLogin } from "react-google-login";

// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { Helmet, HelmetProvider } from "react-helmet-async";

//Import config
import { googleApp } from "../../config";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// actions
import { apiError, loginUser, sendOTP } from "../../store/actions";

// import images
import profileImg from "../../assets/images/card/auth.png";
import logoImg from "../../assets/images/logo/logo.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Trans, withTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import LanguageDropdown from "../../components/CommonForBoth/TopbarDropdown/LanguageDropdown";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      googleAPI: false,
    };
    this.googleResponse.bind(this);
  }

  componentDidMount() {
    const scriptGIS = document.createElement("script");
    scriptGIS.src = "https://accounts.google.com/gsi/client";
    scriptGIS.addEventListener("load", () => {
      google.accounts.id.initialize({
        client_id: googleApp.CLIENT_ID,
        callback: this.googleResponse,
      });

      google.accounts.id.renderButton(
        document.getElementById("google-button-div"), {}
      );
      this.setState({ googleAPI: true });
    });
    document.body.appendChild(scriptGIS);
  }

  //handleGoogleLoginResponse
  googleResponse = response => {
    this.signIn(response.credential, "google");
  };

  signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = { google: res, type };

      this.props.loginUser(
        postData,
        new URLSearchParams(this.props.location.search).get("redirect"),
        this.props.history
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        <HelmetProvider>
          <Helmet>
            <title>{i18n.t("login.title") + " | Teampix"}</title>
          </Helmet>
        </HelmetProvider>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-12" style={{ textAlign: "right" }}>
                        <LanguageDropdown />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">
                            <Trans i18nKey="login.title" />
                          </h5>
                          <p>
                            <Trans i18nKey="login.description" />
                          </p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div className="auth-logo">
                      <Link to="/" className="auth-logo-dark">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-primary">
                            <img src={logoImg} alt="" height="34" />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          email: (this.state && this.state.email) || "",
                          password: (this.state && this.state.password) || "",
                          type: "otp",
                          i18n: localStorage.getItem("i18nextLng") || "fr",
                        }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string()
                            .email(i18n.t("login.enterValidEmail"))
                            .required(i18n.t("login.enterEmail")),
                          password: Yup.string().required(
                            i18n.t("login.enterOTP")
                          ),
                        })}
                        onSubmit={values => {
                          this.props.loginUser(
                            values,
                            new URLSearchParams(this.props.location.search).get(
                              "redirect"
                            ),
                            this.props.history
                          );
                        }}
                      >
                        {({
                          handleBlur,
                          setFieldValue,
                          values,
                          errors,
                          touched,
                        }) => (
                          <Form className="form-horizontal">
                            {/*<h5 className="font-size-14 mb-3 text-center">
                              <Trans i18nKey="login.OTPtitle" />
                        </h5> */}
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                <Trans i18nKey="login.email" />
                              </Label>
                              <Field
                                name="email"
                                type="text"
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                                onChange={e => {
                                  const value = e.target.value || "";
                                  setFieldValue("email", value.toLowerCase());
                                }}
                                onBlur={handleBlur}
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div
                              className={this.props.OTPsent ? "mb-3" : "hidden"}
                            >
                              <Label for="password" className="form-label">
                                <Trans i18nKey="login.OTP" />
                              </Label>
                              <Field
                                name="password"
                                type="password"
                                autoComplete="false"
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div
                              className={
                                this.props.OTPsent ? "mt-3 d-grid" : "hidden"
                              }
                            >
                              <button
                                className="btn btn-secondary btn-block"
                                type="submit"
                                disabled={this.props.loading}
                              >
                                {this.props.loading ? (
                                  <i className="fas fa-circle-notch fa-spin"></i>
                                ) : (
                                  <Trans i18nKey="login.login" />
                                )}
                              </button>
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className={
                                  "btn btn-block " +
                                  (this.props.OTPsent
                                    ? "btn-primary"
                                    : "btn-secondary")
                                }
                                disabled={
                                  this.props.loading || values.email === ""
                                }
                                type="button"
                                onClick={() =>
                                  this.props.sendOTP({
                                    email: values.email,
                                    i18n: values.i18n,
                                  })
                                }
                              >
                                {this.props.loading ? (
                                  <i className="fas fa-circle-notch fa-spin"></i>
                                ) : this.props.OTPsent ? (
                                  <Trans i18nKey="login.resendOTP" />
                                ) : (
                                  <span>
                                    <i className="mdi mdi-lock-outline" />{" "}
                                    <Trans i18nKey="login.sendOTP" />
                                  </span>
                                )}
                              </button>
                            </div>

                            <div>
                              <h5 className="font-size-14 mt-5 mb-3 text-center">
                                <Trans i18nKey="login.or" />
                              </h5>
                              <div className="mt-3 d-grid text-center">
                                <div id="google-button-div" style={{maxWidth: "400px", margin: "0 auto"}}></div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  {/* <p>
                    <Trans i18nKey="login.noAccount" />{" "}
                    <Link
                      to={
                        "register" +
                        (new URLSearchParams(this.props.location.search).get(
                          "redirect"
                        )
                          ? "?code=" +
                            new URLSearchParams(this.props.location.search)
                              .get("redirect")
                              .replace("code/use/", "")
                          : "")
                      }
                      className="fw-medium text-secondary"
                    >
                      {" "}
                      <Trans i18nKey="login.register" />
                    </Link>{" "}
                  </p>
                    */}
                  <p>
                    © {new Date().getFullYear()} Teampix.{" "}
                    <Trans i18nKey="login.craftedWith" />{" "}
                    <i className="mdi mdi-heart text-danger" />{" "}
                    <Trans i18nKey="login.by" /> Teampix
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  apiError: PropTypes.any,
  error: PropTypes.any,
  OTPsent: PropTypes.bool,
  loading: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  sendOTP: PropTypes.func,
  location: PropTypes.object,
  localities: PropTypes.any,
};

Login.defaultProps = {
  OTPsent: false,
};

const mapStateToProps = state => {
  const { error, loading, OTPsent } = state.Login;
  return { error, loading, OTPsent };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, sendOTP })(
    withTranslation()(Login)
  )
);
