import React, { Component } from "react";
import { Row, Col } from "reactstrap";

import { Trans, withTranslation } from "react-i18next";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <Row>
              <Col sm={6}>© {new Date().getFullYear()} Teampix.</Col>
              <Col sm={6}>
                <div className="text-sm-end d-none d-sm-block">
                  <Trans i18nKey="login.craftedWith" />{" "}
                  <i className="mdi mdi-heart text-danger" />{" "}
                  <Trans i18nKey="login.by" /> Teampix
                </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default withTranslation()(Footer);
