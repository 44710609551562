import {
  GET_UPLOAD,
  GET_UPLOAD_FAIL,
  GET_UPLOAD_SUCCESS,
  CONNECT_UPLOAD,
  CONNECT_UPLOAD_FAIL,
  CONNECT_UPLOAD_SUCCESS,
  SUBMIT_UPLOAD,
  SUBMIT_UPLOAD_PROGRESS,
  SUBMIT_UPLOAD_FAIL,
  SUBMIT_UPLOAD_SUCCESS,
} from "./actionTypes";

import { toast } from "react-toastify";
import i18n from "../../i18n/i18n";

export const getUpload = (upload, history) => {
  return {
    type: GET_UPLOAD,
    payload: { upload, history },
  };
};

export const getUploadSuccess = upload => {
  toast.success(i18n.t(upload.message));
  return {
    type: GET_UPLOAD_SUCCESS,
    payload: upload.details,
  };
};

export const getUploadFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: GET_UPLOAD_FAIL,
    payload: fail,
  };
};

export const connectUpload = (upload, history) => {
  return {
    type: CONNECT_UPLOAD,
    payload: { upload, history },
  };
};

export const connectUploadSuccess = upload => {
  toast.success(i18n.t(upload.message));
  return {
    type: CONNECT_UPLOAD_SUCCESS,
    payload: upload.details,
  };
};

export const connectUploadFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: CONNECT_UPLOAD_FAIL,
    payload: fail,
  };
};

export const submitUpload = (upload, history) => {
  return {
    type: SUBMIT_UPLOAD,
    payload: { upload, history },
  };
};

export const submitUploadProgress = (progress) => {
  return{
      type: SUBMIT_UPLOAD_PROGRESS,
      payload: progress
  }
};
export const submitUploadSuccess = upload => {
  toast.success(i18n.t(upload.message));
  return {
    type: SUBMIT_UPLOAD_SUCCESS,
    payload: upload.details,
  };
};

export const submitUploadFail = fail => {
  if (fail.status !== undefined) {
    if (fail.status === "info") {
      toast.info(i18n.t(fail.message));
    } else if (fail.status === "warning") {
      toast.warning(i18n.t(fail.message));
    } else {
      toast.error(i18n.t(fail.message));
    }
  } else {
    toast.error(i18n.t(fail));
  }
  return {
    type: SUBMIT_UPLOAD_FAIL,
    payload: fail,
  };
};
