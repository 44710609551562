export function oldPricing(pack, quantity) {
  // prix selon le pack choisi
  const prices = {
    standard: {
      0: 15,
      6: 25,
      11: 35,
      16: 45,
      21: 55,
      26: 65,
    },
    birthday: {
      0: 20,
      6: 30,
      11: 40,
      16: 50,
      21: 60,
      26: 70,
    },
    premium: {
      0: 30,
      6: 50,
      11: 70,
      16: 90,
      21: 110,
      26: 130,
    },
  };


  let price = prices[pack][0];

  // calcul du prix pack x quantité
  for (const [key, value] of Object.entries(prices[pack])) {
    if (parseInt(quantity) >= parseInt(key)) {
      price = value;
    }
  }

  // au dela de 30 impressions, chaque copie supplémentaire coute :
  // 2€ par petite
  // 4€ par grande
  if (parseInt(quantity) > 30) {
    if (pack === "premium") {
      price += (parseInt(quantity) - 30) * 4;
    } else {
      price += (parseInt(quantity) - 30) * 2;
    }
  }

  return price;
}

export function pricing(pack, quantity, prices) {

  const fixedQuantityPacks = ["solo","family", "group"];

  // uen seule impression, c'est le minimum
  let price = prices["large"]["sale"][1];
  if (pack === "standard") price = prices["small"]["sale"][1];

  if(!fixedQuantityPacks.includes(pack)){
    for (let index = 2; index <= quantity; index++) {
      // on prend le "next" qui est le prix final pour toutes les impressions suivantes par defaut
      let priceToAdd =
        pack === "premium"
          ? prices["large"]["sale"]["next"]
          : prices["small"]["sale"]["next"];
  
      // a t on un prix pour la "index"-ième impression ?
      if (pack === "premium" && prices["large"]["sale"][index] !== undefined)
        priceToAdd = prices["large"]["sale"][index];
      if (pack !== "premium" && prices["small"]["sale"][index] !== undefined)
        priceToAdd = prices["small"]["sale"][index];
  
      price += priceToAdd;
    }  
  }else{
    price = prices[pack]["sale"][1];
  }
  
  return price;
}
