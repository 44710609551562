import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";

import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

import { Helmet, HelmetProvider } from "react-helmet-async";

//Import Images
import error from "../../assets/images/error-img.png";

class Page404 extends Component {
  render() {
    //meta title
    return (
      <React.Fragment>
        <HelmetProvider>
          <Helmet>
            <title>{i18n.t("page404.title") + " | Teampix"}</title>
          </Helmet>
        </HelmetProvider>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row>
              <Col lg="12">
                <div className="text-center mb-5">
                  <h1 className="display-2 fw-medium">
                    4
                    <i className="bx bx-buoy bx-spin text-primary display-3" />4
                  </h1>
                  <h4 className="text-uppercase">
                    <Trans i18nKey="page404.notFound" />
                  </h4>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8" xl="6">
                <div>
                  <img src={error} alt="" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Page404));
