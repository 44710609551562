import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Label } from "reactstrap";
import Jimp from "jimp/es";
import { ROOT_CLIENT, ROOT_BUCKET } from "../../../helpers/url";

//i18n
import { Trans, withTranslation } from "react-i18next";

// file type
import { PHOTO_FILE_TYPE } from "../../../helpers/files";

class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      divider: 2,
      actualRatio: "",
      loading: false,
      previewNoText: null,
      preview: null,
      error: "",
    };
  }

  autoOrientation(image, stateName = "") {
    if (this.props.exif.Orientation > 1) {
      image.clone().getBase64(Jimp.MIME_JPEG, (err, res) => {
        if (stateName == "previewNoText") {
          this.setState({
            previewNoText: res,
          });
        } else {
          this.props.handlePreview(res);
          this.setState({
            loading: false,
            preview: res,
          });
        }
      });
    } else {
      image.getBase64(Jimp.MIME_JPEG, (err, res) => {
        if (stateName == "previewNoText") {
          this.setState({
            previewNoText: res,
          });
        } else {
          this.props.handlePreview(res);
          this.setState({
            loading: false,
            preview: res,
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.photo !== prevProps.photo ||
      this.props.text !== prevProps.text
    ) {
      this.props.handlePreview("");
      this.setState({
        loading: true,
        preview: null,
        error: "",
      });

      if (!this.props.photo) {
        this.props.handlePreview("");
        this.setState({
          loading: false,
          preview: null,
          previewNoText: null,
          error: "",
        });
      } else {
        if (this.props.photo !== prevProps.photo) {
          if (!PHOTO_FILE_TYPE.includes(this.props.photo.type)) {
            this.props.handlePreview("");
            this.setState({
              loading: false,
              preview: null,
              previewNoText: null,
              error: "",
            });
          } else {
            let reader = new FileReader();
            reader.onloadend = () => {
              Jimp.read(reader.result)
                .then(image => {
                  // on redimensionne au plus haut ratio possible ( à eux de faire des rotations d'images si c'est une square ou une portrait)

                  const actualRatio =
                    image.bitmap.width >= image.bitmap.height
                      ? "landscape"
                      : "portrait";

                  // déterminer le bon ratio
                  this.setState({
                    actualRatio,
                  });

                  // on part d'une landscape (photo d'équipe)
                  image.cover(
                    this.props.config[actualRatio].width / this.state.divider,
                    this.props.config[actualRatio].height / this.state.divider
                  );

                  // on applique l'overlay
                  Jimp.read(
                    ROOT_BUCKET +
                      "/config/" +
                      this.props.idPlace +
                      "/" +
                      actualRatio +
                      this.props.size +
                      "Preview.png"
                  )
                    .then(overlay => {
                      image.composite(overlay, 0, 0);
                      image.quality(100);

                      this.autoOrientation(image, "previewNoText");

                      let font = Jimp.FONT_SANS_16_BLACK;
                      if (
                        this.props.config[actualRatio].legend.color == "white"
                      )
                        font = Jimp.FONT_SANS_16_WHITE;

                      Jimp.loadFont(ROOT_CLIENT + font).then(font => {
                        image.print(
                          font,
                          this.props.config[actualRatio].legend.x /
                            this.state.divider,
                          this.props.config[actualRatio].legend.y /
                            this.state.divider,
                          {
                            text: this.props.text,
                            alignmentX: Jimp.HORIZONTAL_ALIGN_CENTER,
                            alignmentY: Jimp.VERTICAL_ALIGN_MIDDLE,
                          },
                          this.props.config[actualRatio].legend.width /
                            this.state.divider,
                          this.props.config[actualRatio].legend.height /
                            this.state.divider,
                          (err, imageRes) => {
                            this.autoOrientation(imageRes, "preview");

                            // let finalPic = imageRes.getBase64Async(
                            //   Jimp.MIME_JPEG
                            // );
                            // finalPic.then(result => {
                            //   this.props.handlePreview(result);
                            //   this.setState({
                            //     loading: false,
                            //     preview: result,
                            //   });
                            // });
                          }
                        );
                      });
                    })
                    .catch(error => {
                      // Handle an exception.
                      this.props.handlePreview("");
                      this.setState({ error });
                    });
                })
                .catch(error => {
                  // Handle an exception.
                  console.log(error);
                  this.props.handlePreview("");
                  this.setState({ error });
                });
            };
            reader.readAsDataURL(this.props.photo);
          }
        } else if (
          this.props.text !== prevProps.text &&
          this.state.previewNoText !== null
        ) {
          Jimp.read(this.state.previewNoText).then(imageNoText => {
            let font = Jimp.FONT_SANS_16_BLACK;
            if (
              this.props.config[this.state.actualRatio].legend.color == "white"
            )
              font = Jimp.FONT_SANS_16_WHITE;

            Jimp.loadFont(ROOT_CLIENT + font).then(font => {
              imageNoText.print(
                font,
                this.props.config[this.state.actualRatio].legend.x /
                  this.state.divider,
                this.props.config[this.state.actualRatio].legend.y /
                  this.state.divider,
                {
                  text: this.props.text,
                  alignmentX: Jimp.HORIZONTAL_ALIGN_CENTER,
                  alignmentY: Jimp.VERTICAL_ALIGN_MIDDLE,
                },
                this.props.config[this.state.actualRatio].legend.width /
                  this.state.divider,
                this.props.config[this.state.actualRatio].legend.height /
                  this.state.divider,
                (err, imageTextUpdated) => {
                  let finalPic = imageTextUpdated.getBase64Async(
                    Jimp.MIME_JPEG
                  );
                  finalPic.then(result => {
                    this.props.handlePreview(result);
                    this.setState({
                      loading: false,
                      preview: result,
                    });
                  });
                }
              );
            });
          });
        }
      }
    }
  }

  render() {
    if (
      !this.props.photo ||
      !this.props.photo.name ||
      (this.props.photo && !PHOTO_FILE_TYPE.includes(this.props.photo.type))
    ) {
      return "";
    }

    if (this.state.error !== "") {
      return (
        <div className="text-center" style={{ position: "relative" }}>
          <img
            src={
              ROOT_BUCKET +
              "/config/" +
              this.props.idPlace +
              "/" +
              this.state.actualRatio +
              this.props.size +
              "Preview.png"
            }
            alt={"no preview " + this.props.size + " " + this.state.actualRatio}
            name={
              "no preview " + this.props.size + " " + this.state.actualRatio
            }
            className="img-thumbnail"
          />
          <h4
            className="text-danger"
            style={{
              position: "absolute",
              top: "40%",
              left: "10%",
              width: "80%",
            }}
          >
            {JSON.stringify(this.state.error)}
          </h4>
        </div>
      );
    }

    if (this.state.loading) {
      if (this.state.actualRatio !== "") {
        return (
          <div className="text-center" style={{ position: "relative" }}>
            <img
              src={
                ROOT_BUCKET +
                "/config/" +
                this.props.idPlace +
                "/" +
                this.state.actualRatio +
                this.props.size +
                "Preview.png"
              }
              alt={
                "no preview " + this.props.size + " " + this.state.actualRatio
              }
              name={
                "no preview " + this.props.size + " " + this.state.actualRatio
              }
              className="img-thumbnail"
            />
            <h1
              style={{
                position: "absolute",
                top: "40%",
                left: "10%",
                width: "80%",
                color: this.props.color,
              }}
            >
              <i className="fas fa-cog fa-spin"></i>
            </h1>
          </div>
        );
      } else {
        return (
          <div className="text-center">
            <h1
              style={{
                color: this.props.color,
              }}
            >
              <i className="fas fa-cog fa-spin"></i>
            </h1>
          </div>
        );
      }
    }

    return (
      <div className="" style={{ position: "relative" }}>
        <Label>
          <Trans i18nKey={"upload.preview" + this.props.size}></Trans>
        </Label>

        <img
          src={this.state.preview}
          alt={this.props.photo.name}
          name={"preview" + this.props.size}
          className="img-thumbnail"
        />
      </div>
    );
  }
}

Preview.propTypes = {
  photo: PropTypes.object,
  idPlace: PropTypes.number,
  config: PropTypes.object,
  handlePreview: PropTypes.func,
  size: PropTypes.string,
  exif: PropTypes.object,
  text: PropTypes.string,
  color: PropTypes.string,
};

export default withRouter(withTranslation()(Preview));
