import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";

import Breadcrumbs from "../../components/Common/Breadcrumb";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  // SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../assets/scss/custom/plugins/_datatables.scss";

//store
import { listUsers, loginAs } from "../../store/actions";

//i18n
import i18n from "../../i18n/i18n";
import { Trans, withTranslation } from "react-i18next";

import { ROOT_BUCKET } from "../../helpers/url";

class Users extends Component {
  constructor(props) {
    super(props);
    this.handleLoginAs = this.handleLoginAs.bind(this);
  }

  componentDidMount() {
    this.props.listUsers(this.props.history);
  }

  handleName(cell, row) {
    return cell + " " + row.lastname;
  }

  handleContact(cell, row) {
    return (
      <span>
      <i className="fas fa-envelope"></i>{" "} {cell}
        <br />
        <i className="fas fa-phone-alt"></i>{" "} {row.phone}
      </span>
    );
  }

  handlePlaces(cell) {
    return cell.split(",").map((place, key) => {
      return (
        <div key={key}>
          <img
            src={ROOT_BUCKET + "/" + place.replace("logo", "logo-dark")}
            style={{ height: "26px" }}
          ></img>
        </div>
      );
    });
  }

  handleLoginAs(cell) {
    return (
      <Button
        onClick={() => {
          this.props.loginAs({ idAccount: cell }, this.props.history);
        }}
        className="btn btn-primary"
      >
        <Trans i18nKey="users.loginAs"></Trans>
      </Button>
    );
  }

  render() {
    const columns = [
      {
        dataField: "firstname",
        text: i18n.t("users.name"),
        formatter: this.handleName,
        sort: true,
        searchable: true,
      },
      {
        dataField: "email",
        text: i18n.t("users.details"),
        formatter: this.handleContact,
        sort: true,
        searchable: true,
      },
      {
        dataField: "placeLogosList",
        text: i18n.t("users.places"),
        formatter: this.handlePlaces,
        sort: false,
        searchable: true,
      },
      {
        dataField: "idAccount",
        text: i18n.t("users.loginAs"),
        formatter: this.handleLoginAs,
        sort: false,
        searchable: false,
      },
    ];

    const defaultSorted = [
      {
        dataField: "name",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: 10,
      totalSize: (this.props.users && this.props.users.length) || 0, // reuser later with size(customers),
      custom: true,
    };

    // Select All Button operation
    const selectRow = {
      mode: "radio",
      hideSelectColumn: true,
      clickToSelect: true,
      classes: "bg-primary bg-soft",
    };

    const { SearchBar } = Search;

    return (
      <React.Fragment>
        <div className="page-content">
          <HelmetProvider>
            <Helmet>
              <title>{i18n.t("sidebar.users") + " | Teampix"}</title>
            </Helmet>
          </HelmetProvider>
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title={"Teampix"}
              breadcrumbItem={this.props.t("sidebar.users")}
            />
            {this.props.users ? (
              <Row className="justify-content-center">
                <Col lg="12">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <div className="p-2 mb-5">
                        <h5 className="mt-3 mb-3">
                          <Trans i18nKey="sidebar.users" />
                        </h5>

                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="idAccount"
                          columns={columns}
                          data={this.props.users}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="idAccount"
                              columns={columns}
                              data={this.props.users}
                              // search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                            placeholder={i18n.t("place.search")}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField={"idAccount"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          hover={true}
                                          defaultSorted={defaultSorted}
                                          selectRow={selectRow}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                          noDataIndication={
                                            <Trans i18nKey="user.emptyListUsers"></Trans>
                                          }
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      {
                                        // <div className="d-inline">
                                        //   <SizePerPageDropdownStandalone
                                        //     {...paginationProps}
                                        //   />
                                        // </div>
                                      }
                                      <div className="text-md-right ms-auto">
                                        <div className="pagination pagination-rounded justify-content-end">
                                          <PaginationListStandalone
                                            {...paginationProps}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                        <br />
                        <br />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.any,
  error: PropTypes.any,
  users: PropTypes.array,
  listUsers: PropTypes.func,
  loginAs: PropTypes.func,
};

const mapStateToProps = state => {
  const { error, loading, users } = state.User;
  return { error, loading, users };
};

export default connect(mapStateToProps, {
  listUsers,
  loginAs,
})(withRouter(withTranslation()(Users)));
