import { combineReducers } from "redux";

import Layout from "./layout/reducer";

// Authentication
import Login from "./authentication/login/reducer";
// import Activation from "./authentication/activate/reducer"
// import Account from "./authentication/register/reducer"
import Profile from "./authentication/profile/reducer";

// Upload
import Upload from "./upload/reducer";

// Place
import Place from "./place/reducer";

// Hardware
import Hardware from "./hardware/reducer";

// User
import User from "./user/reducer";

// Order
import Order from "./order/reducer";

// Help
import Help from "./help/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  // Account,
  // Activation,
  Profile,
  Upload,
  Place,
  Hardware,
  User,
  Order,
  Help,
});

export default rootReducer;
