import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { map } from "lodash";

import { connect } from "react-redux";

import { Link, withRouter } from "react-router-dom";

import { ROOT_BUCKET } from "../../../helpers/url";
// import logo from "../../assets/images/logo/logo.png";
// import logoLight from "../../assets/images/logo/logo-light.png";
import logoDark from "../../../assets/images/logo/logo-dark.png";

//i18n
import { withTranslation } from "react-i18next";

class PlaceDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
    };
  }

  componentDidMount() {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    this.setState({ authUser });
  }

  toggle = () => {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  };

  render() {
    const { authUser, menu } = this.state;

    if (!authUser) return "";
    if (!authUser.places) return "";

    let logo = (
      <span className="display-6" style={{ fontFamily: "impact" }}>
        404
      </span>
    );
    {
      map(Object.keys(authUser.places), key => {
        if (this.props.match.params.idPlace == authUser.places[key].id)
          logo = (
            <img
              src={
                this.props.match.params.idPlace
                  ? ROOT_BUCKET +
                    "/config/" +
                    this.props.match.params.idPlace +
                    "/logo-dark.png"
                  : logoDark
              }
              alt=""
              height="42"
              className="me-1"
            />
          );
      });
    }

    return (
      <React.Fragment>
        <Dropdown isOpen={menu} toggle={this.toggle} className="d-inline-block">
          <DropdownToggle className="btn header-item" tag="button">
            {logo}
          </DropdownToggle>
          <DropdownMenu className="language-switch dropdown-menu-end">
            {map(Object.keys(authUser.places), key => (
              <DropdownItem
                key={key}
                className={`notify-item ${
                  this.props.match.params.idPlace === authUser.places[key].id
                    ? "active"
                    : "none"
                }`}
              >
                <Link to={"/place/" + authUser.places[key].id}>
                  <img
                    src={
                      ROOT_BUCKET +
                      "/config/" +
                      authUser.places[key].id +
                      "/logo-dark.png"
                    }
                    alt={authUser.places[key].name}
                    className="me-1"
                    height="42"
                  />
                </Link>
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

PlaceDropdown.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  user: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  loading: PropTypes.bool,
};

const mapStateToProps = state => {
  const { error, success, user, loading } = state.Profile;
  return { error, success, user, loading };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PlaceDropdown)));
